/* eslint-disable sort-keys */
/* eslint-disable quotes */
import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import GA from 'services/gaService'
import VideoImage from 'components/common/VideoImage'
import WelcomeNumberNav from './WelcomeNumberNav'
import { inc, path, prop } from 'ramda'
import { Box, Button, Stack, Typography } from '@mui/material'
import { useAnalytics, useCurrentAppType } from 'hooks'
import { Link } from 'react-router-dom'
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded'
import { useSelector } from 'react-redux'

const WelcomeSectionStories = () => {
  const { cloudfront_domain } = useSelector(prop('school'))
  const { isVeteran, isResponder } = useCurrentAppType()
  const { sendEvent } = useAnalytics()

  const getVeteranVideos = () => [
    {
      background: '/images/welcome/video-backgrounds/veteran/solutions.png',
      caption:
        'The greatest minds in Veterans’ healing highlight community-based, non-medicalized solutions for Veterans returning home.',
      name: 'Solutions',
      url:
        'https://' +
        cloudfront_domain +
        '/misc/welcome-videos/veteran/veteran_journey_home_final_trailer___warrior_films___movie_trailers.mp4 (1080p).mp4'
    },
    {
      background: '/images/welcome/video-backgrounds/veteran/bens_story.png',
      caption:
        'A former Army Ranger and sniper, challenged by suicidal ideation, divorce, and alcoholism, fights to reconnect with his family and resume a place of leadership in civilian society.',
      name: "Ben's Story",
      url:
        'https://' +
        cloudfront_domain +
        '/misc/welcome-videos/veteran/bens_story_trailer.mov (1080p).mp4'
    },
    {
      background: '/images/welcome/video-backgrounds/veteran/kalani.png',
      caption:
        'Divorced, suicidal, and homeless, A Marine Captain experiences transformation through emotionally authentic healing circles. ',
      name: "Kalani's Story",
      url:
        'https://' +
        cloudfront_domain +
        '/misc/welcome-videos/veteran/kalani_trailer_210428_master.mov (1080p).mp4'
    },
    {
      background:
        '/images/welcome/video-backgrounds/veteran/onblackmountain.png',
      caption:
        'Twenty-two women Veterans transform through a 4-month meditation/mindfulness-based healing retreat. \n',
      name: 'On a primary.main Mountain',
      url:
        'https://' +
        cloudfront_domain +
        '/misc/welcome-videos/veteran/onblackmountain_trailer_h264_final (1080p).mp4'
    },
    {
      background: '/images/welcome/video-backgrounds/veteran/liota.png',
      caption:
        'Twelve Veterans undergo an ancient 12-day Vision Fast ceremony – out in the wilderness with minimal shelter, fasting, alone.',
      name: 'Leaving it to the Land',
      url:
        'https://' +
        cloudfront_domain +
        '/misc/welcome-videos/veteran/liotl_trailer_final_v1 (1080p).mp4'
    }
  ]
  const getResponderVideos = () => [
    {
      background:
        '/images/welcome/video-backgrounds/responder/911-Dispatcher-Trauma-&-Burnout-_-PTSD-&-Stress-for-First-Responders.jpg',
      caption:
        'A 911 dispatcher talks about the pace of the job and dealing with burnout.',
      name: 'Strength Through Community',
      url:
        'https://' +
        cloudfront_domain +
        '/misc/welcome-videos/responder/911-Dispatcher-Trauma-&-Burnout-_-PTSD-&-Stress-for-First-Responders.mp4'
    },
    {
      background:
        '/images/welcome/video-backgrounds/responder/Rick-Balentine-Fire-Chief-Testimonial.jpg',
      caption:
        'A fire chief offers advice on positivity, disseminating information, and caring for yourself and others as a leader.',
      name: 'Rick Balentine Fire Chief Testimonial',
      url:
        'https://' +
        cloudfront_domain +
        '/misc/welcome-videos/responder/Rick-Balentine-Fire-Chief-Testimonial.mp4'
    },
    {
      background:
        '/images/welcome/video-backgrounds/responder/David-Ray-Allen-EMT-Testimonial.jpg',
      caption:
        'An EMT talks about peer support, decompression, and finding work-life balance.',
      name: 'David Ray Allen EMT Testimonial',
      url:
        'https://' +
        cloudfront_domain +
        '/misc/welcome-videos/responder/David-Ray-Allen-EMT-Testimonial.mp4'
    }
  ]

  const getVideos = () => {
    if (isVeteran) return getVeteranVideos()
    return getResponderVideos()
  }

  const [currentVideo, setCurrentVideo] = useState(0)

  const veteranHeadline =
    'Get exclusive access to Veteran Stories by Warrior Films.'
  const veteranContent =
    'In this 5 part series, watch the real stories of Veterans who have transitioned back to civilian life and learn from the greatest minds who highlight community-based, non-medicalized solutions for Veterans returning home.'
  const veteranLink = 'Sign up to see the full length films'

  const responderHeadline = 'Learn from a shared community.'
  const responderContent =
    'Sometimes, it takes another responder to truly understand the unique stresses of the job. Hear tips, tools, and life hacks from other responders. No responder should feel alone, on the job or off.'
  const responderLink = 'Hear more stories'

  const handleNav = index => {
    if (index !== currentVideo) {
      setCurrentVideo(index)
    }
  }

  const sendKeenIOEvent = (element_title, click_element) => {
    sendEvent('welcome_page', {
      click_element: click_element,
      element_title: element_title,
      section: 'stories'
    })
  }

  const HashSquare = () => {
    const hash = '/images/welcome/hash-square.svg'

    return (
      <Box
        sx={{
          position: 'absolute',
          top: isResponder ? { xs: 0, lg: '3%' } : { xs: '5%', md: 70 },
          width: { xs: 1, lg: '50%' }
        }}
      >
        <Box
          sx={{
            background: `url(${hash})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            pt: '100%',
            width: 1
          }}
        />
      </Box>
    )
  }

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: { xs: '1fr', lg: 'repeat(2, auto)' },
        pb: isResponder ? '5%' : { xs: '25%', lg: '5%' },
        position: 'relative',
        pt: isResponder
          ? { xs: '10%', lg: 0, xl: '10%' }
          : { xs: 10, lg: 8, xl: 10 }
      }}
    >
      <Stack
        sx={{
          gridColumn: isResponder ? { xs: 1, lg: 2 } : 1,
          gridRow: 1,
          p: 2
        }}
      >
        <HashSquare />
        <VideoImage
          background={path([currentVideo, 'background'], getVideos())}
          handleGA={() =>
            GA.click(
              'Welcome-Stories-Video: ' +
                path([currentVideo, 'name'], getVideos()),
              'Click'
            )
          }
          handleKeen={sendKeenIOEvent}
          videoUrl={path([currentVideo, 'url'], getVideos())}
        />
        <Box>
          <WelcomeNumberNav
            caption={path([currentVideo, 'caption'], getVideos())}
            currentItem={currentVideo}
            handleClick={(e, index) => {
              e.preventDefault()
              handleNav(index)
              sendKeenIOEvent('Video Nav ' + inc(index), 'Button')
            }}
            items={getVideos()}
            positionNumbersOnTop
          />
        </Box>
      </Stack>

      <Stack
        sx={{
          gridRow: { xs: 2, lg: 1 },
          p: { xs: 2, lg: '15% 7%' }
        }}
      >
        <Typography color="primary" variant="h2">
          <FormattedMessage
            defaultMessage="{headline}"
            id="AEeXEL"
            values={{
              headline: isResponder ? responderHeadline : veteranHeadline
            }}
          />
        </Typography>
        <Typography mb={2} mt=".8em" variant="bodyLarge">
          <FormattedMessage
            defaultMessage="{content}"
            id="zb24ln"
            values={{ content: isVeteran ? veteranContent : responderContent }}
          />
        </Typography>
        <Button
          endIcon={<ArrowForwardRoundedIcon />}
          LinkComponent={Link}
          onClick={() => sendKeenIOEvent('Create a new Account', 'Link')}
          sx={{
            color: 'primary.main',
            fontWeight: 700,
            px: 0
          }}
          to="/sign-up"
          variant="text"
        >
          <FormattedMessage
            defaultMessage="{ctaLink}"
            id="QBkvSl"
            values={{
              ctaLink: isResponder ? responderLink : veteranLink
            }}
          />
        </Button>
      </Stack>
    </Box>
  )
}

export default WelcomeSectionStories
