import { equals, prop } from 'ramda'
import { useSelector } from 'react-redux'

const useCurrentAppType = () => {
  const { app_type } = useSelector(prop('school'))

  const isVeteran = equals('veteran', app_type)
  const isResponder = equals('responder', app_type)

  return {
    isResponder,
    isVeteran
  }
}

export default useCurrentAppType
