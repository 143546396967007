import { Box, Button, Container, Stack, Typography } from '@mui/material'

import Highlight from 'components/common/Highlight'

import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { prop } from 'ramda'
import PropTypes from 'prop-types'
import { useAnalytics, useCurrentAppType } from 'hooks'

const CTASection = ({ blockTitle = 'OFD Sign Up' }) => {
  const { isVeteran } = useCurrentAppType()
  const { sendEvent } = useAnalytics()

  const content = () => {
    if (isVeteran)
      return {
        highlight: (
          <FormattedMessage defaultMessage="Join other veterans" id="I+jTow" />
        ),
        img: '/images/ovs/cta.jpg',
        title: (
          <FormattedMessage
            defaultMessage="by signing up for Operation Veteran Strong"
            id="npJldT"
          />
        )
      }
    return {
      highlight: (
        <FormattedMessage defaultMessage="Join other responders" id="Dxmz3j" />
      ),
      img: '/images/ovs/cta.jpg',
      title: (
        <FormattedMessage
          defaultMessage="by signing up for Responder Strong"
          id="pLBPa4"
        />
      )
    }
  }

  return (
    <Box sx={{ bgcolor: 'white', py: 4 }}>
      <Container maxWidth="xl">
        <Stack alignItems="center" spacing={3}>
          <Stack direction="row" spacing={1}>
            <Typography variant="h3">
              <FormattedMessage
                defaultMessage="<h>{highlight}</h> {title}"
                id="SxOyDR"
                values={{
                  h: chunks => <Highlight>{chunks}</Highlight>,
                  highlight: prop('highlight', content()),
                  title: prop('title', content())
                }}
              />
            </Typography>
          </Stack>
          <Typography sx={{ px: 2 }} variant="body2">
            <FormattedMessage
              defaultMessage="Its 100% free and confidential, no exceptions."
              id="PqAPAU"
            />
          </Typography>
          <Button
            LinkComponent={Link}
            onClick={() =>
              sendEvent('block_interactions', {
                block_title: blockTitle,
                click_element: 'Link',
                element_title: 'Sign Up CTA',
                path: window.location.pathname
              })
            }
            to="/sign-up"
          >
            <FormattedMessage defaultMessage="sign up" id="yv/kr9" />
          </Button>
          <Box
            sx={{
              background: `url(${prop('img', content())})`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              height: 250,
              width: 1
            }}
          />
        </Stack>
      </Container>
    </Box>
  )
}

CTASection.propTypes = {
  blockTitle: PropTypes.string
}

export default CTASection
