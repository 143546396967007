/* eslint-disable sort-keys */
import { Box, Typography } from '@mui/material'

import { FormattedMessage } from 'react-intl'
import { useCurrentAppType } from 'hooks'

const WelcomeAbout = () => {
  const { isVeteran } = useCurrentAppType()

  // Default object to fill in unspecified fields and all text if no different app types given
  const defaultText = {
    aboutDescription: (
      <FormattedMessage
        defaultMessage="A free and confidential space to find resources, learn skills, and take action to support your well-being."
        id="uS5RhI"
      />
    ),
    aboutHeader: (
      <FormattedMessage defaultMessage="About YOU at Your Best" id="AHs81m" />
    ),
    communityDescription: (
      <FormattedMessage
        defaultMessage="Hear stories from community members on how they navigate topics like finances, stress management, relationships, and everything between"
        id="p9GOi1"
      />
    ),
    communityHeader: (
      <FormattedMessage
        defaultMessage="Stories from your Community"
        id="qvltaL"
      />
    ),
    connectingDescription: (
      <FormattedMessage
        defaultMessage="Whether it's for you, or a someone in your life, we can help point to the best resources to help."
        id="Gor7C2"
      />
    ),
    connectingHeader: (
      <FormattedMessage
        defaultMessage="Connect to the right resources"
        id="z3WwBV"
      />
    ),
    whoDescription: (
      <FormattedMessage
        defaultMessage="Well-being is unique and personal to each person. YOU at Your Best personalizes your experience based on your interests, priorities, and unique identities."
        id="dOERbT"
      />
    ),
    whoHeader: (
      <FormattedMessage defaultMessage="Personalized for You" id="/xU3F1" />
    )
  }

  /**
   * Function to get text objects for specific app types
   * @input string: app type currently in use
   * @return object: object of key value pairs. Keys are identifiers and values are Components to render for app type
   */
  const getDynamicText = () => {
    if (isVeteran)
      return {
        ...defaultText,
        aboutDescription: (
          <FormattedMessage
            defaultMessage="A free and confidential place for Vets and their loved ones to connect with local supports, hear stories from other Vets, and take action to be at their best."
            id="RPtVWI"
          />
        ),
        aboutHeader: (
          <FormattedMessage defaultMessage="About OVS" id="JqWWhi" />
        ),
        communityDescription: (
          <FormattedMessage
            defaultMessage="Hear from other Veterans on how they navigate topics like transition to civilian life, substance use, career growth, and everything in between."
            id="aPzgup"
          />
        ),
        communityHeader: (
          <FormattedMessage
            defaultMessage="Stories from the Veteran Community"
            id="Kzmybu"
          />
        ),
        connectingDescription: (
          <FormattedMessage
            defaultMessage="Whether it's for you, or a Veteran in your life, we have local and national resources on hand to help."
            id="8MaI9a"
          />
        ),
        connectingHeader: (
          <FormattedMessage
            defaultMessage="Connecting Veterans to the right resources"
            id="APHAWo"
          />
        ),
        whoDescription: (
          <FormattedMessage
            defaultMessage="Veterans understand the veteran experience best. That's why OVS was created by Veterans, for Veterans."
            id="lHJXrT"
          />
        ),
        whoHeader: (
          <FormattedMessage defaultMessage="By Vets, For Vets" id="S6/pRB" />
        )
      }
    return {
      ...defaultText,
      communityDescription: (
        <FormattedMessage
          defaultMessage="Hear from other Emergency Responders on how they navigate topics like work-life balance, sleep, relationships, and everything in between."
          id="N2o7Re"
        />
      ),
      communityHeader: (
        <FormattedMessage
          defaultMessage="Stories from the Emergency Responder Community"
          id="sXGQoJ"
        />
      ),
      connectingDescription: (
        <FormattedMessage
          defaultMessage="Whether it's for you, or a Emergency Responders in your life, we have local and national resources on hand to help."
          id="JM8xVw"
        />
      ),
      connectingHeader: (
        <FormattedMessage
          defaultMessage="Connecting Emergency Responders to the right resources"
          id="Tjvnsu"
        />
      ),
      whoDescription: (
        <FormattedMessage
          defaultMessage="Sometimes only Emergency Responders understand the full picture of serving our community. That's why RS was created by responders, for responders."
          id="9hEf9h"
        />
      ),
      whoHeader: (
        <FormattedMessage
          defaultMessage="By Responder, For Responders"
          id="jAbUYM"
        />
      )
    }
  }

  const {
    communityDescription,
    communityHeader,
    whoDescription,
    whoHeader,
    connectingDescription,
    connectingHeader
  } = getDynamicText()

  const getImage = () => {
    if (isVeteran) return 'url(/images/ovs/explore/ovs-about.svg)'
    return 'url(/images/responder/About-Responder-Strong.png)'
  }

  return (
    <Box sx={{ pb: 5 }}>
      <Box
        sx={{
          mt: { xs: 4, md: 7.5 },
          mx: 'auto',
          maxWidth: 1024,
          textAlign: 'center',
          width: 0.8
        }}
      >
        <Box
          sx={{
            display: 'grid',
            gridGap: 48,
            gridTemplateColumns: { xs: '1fr', md: 'repeat(2, 1fr)' }
          }}
        >
          <Box
            sx={{
              background: getImage(),
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'fill',
              minHeight: 345,
              width: 1
            }}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gridGap: 16
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gridGap: 8
              }}
            >
              <Typography variant="h4">{communityHeader}</Typography>
              <Typography variant="body2">{communityDescription}</Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gridGap: 8
              }}
            >
              <Typography variant="h4">{whoHeader}</Typography>
              <Typography variant="body2">{whoDescription}</Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gridGap: 8
              }}
            >
              <Typography variant="h4">{connectingHeader}</Typography>
              <Typography variant="body2">{connectingDescription}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default WelcomeAbout
