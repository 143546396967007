/* eslint-disable sort-keys */
import { Avatar, Box, Zoom, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { or, defaultTo, toUpper, split, isNil, remove, join, prop } from 'ramda'
import {
  useGetPublicFeaturedContentQuery,
  useGetPublicUserDomainsQuery
} from 'api/publicApi'
import { Link } from 'react-router-dom'
import useAnalytics from 'hooks/useAnalytics'
import { useSelector } from 'react-redux'

import FeaturedTopics from './FeaturedTopics'

const Topics = () => {
  const { domain } = useSelector(prop('school'))
  const { sendEvent } = useAnalytics()

  const { data: userDomains, isFetching: isFetchingDomains } =
    useGetPublicUserDomainsQuery(domain, { skip: isNil(domain) })

  const { data: featuredContent, isFetching: isFetchingContent } =
    useGetPublicFeaturedContentQuery(domain, { skip: isNil(domain) })

  if (or(isFetchingDomains, isFetchingContent)) return null

  // Below are copied from the OFD components
  // TODO: look at better way to do this
  const getTopicDataFromSubdomain = subdomain => ({
    id: subdomain.id,
    image: subdomain.display_image,
    internalURL: subdomain.link, //`/explore/subdomain/${subdomain.id}`,
    title: subdomain.title
  })

  const getTopicDataFromGroup = group => ({
    id: group.id,
    image: group.frontend_display_image,
    internalURL: `/explore/group/${group.id}`,
    title: group.display_text
  })

  const subdomains = defaultTo(
    [],
    userDomains
      ?.reduce((subdomains, d) => subdomains.concat(d.subdomains), [])
      ?.filter(s => featuredContent?.subdomains.includes(s.id))
      ?.map(s => getTopicDataFromSubdomain(s))
  )

  const groups = featuredContent?.groups.map(g => getTopicDataFromGroup(g))
  // adding this groupDefault when featuredContent is null it returns undefined from groups
  // breaking the map below, so defaulting to empty array so the topicsList
  // list of topics concated with groups
  const groupDefault = defaultTo([], groups)
  const topics = subdomains?.concat(groupDefault)

  const renderLabel = (url, title) => `${toUpper(split('/', url)[1])} ${title}`

  const trimUrl = url => {
    if (url.includes('explore')) return join('/', remove(1, 1, split('/', url)))
    return url
  }

  return (
    <Box>
      <Box sx={{ pl: 2, pr: 2, maxWidth: 1024, textAlign: 'center', width: 1 }}>
        <Box sx={{ display: 'grid', gridGap: 50 }}>
          <Typography color="primary" fontSize="35px" variant="h2">
            <FormattedMessage defaultMessage="Navigate by Topic" id="uOapfD" />
          </Typography>
          <Box
            sx={{
              alignItems: 'flex-start',
              display: 'grid',
              gridGap: 20,
              gridTemplateColumns: {
                xs: 'repeat(2, 1fr)',
                sm: 'repeat(3, 1fr)',
                lg: 'repeat(6, 1fr)'
              }
            }}
          >
            {topics.map(({ id, internalURL, image, title }) => (
              <Zoom in key={id}>
                <Box
                  alignItems="center"
                  aria-label={renderLabel(internalURL, title)}
                  component={Link}
                  onClick={() =>
                    sendEvent('block_interactions', {
                      block_title: 'OFD Explore Topics',
                      click_element: 'Link',
                      element_title: title,
                      path: window.location.pathname
                    })
                  }
                  sx={{
                    display: 'grid',
                    gridGap: 16,
                    justifyItems: 'center',
                    ':hover > .MuiBox-root': {
                      display: 'block'
                    },
                    ':hover > span': {
                      fontFamily: 'mark-ot-bold'
                    }
                  }}
                  to={`/public${trimUrl(internalURL)}`}
                >
                  <Avatar
                    alt={title}
                    src={image}
                    sx={{ position: 'relative' }}
                    variant="topic"
                  />
                  <Typography
                    component={'span'}
                    sx={{
                      color: 'primary.main'
                    }}
                    variant="body2"
                  >
                    <FormattedMessage
                      defaultMessage="{title}"
                      id="2YgLVn"
                      values={{ title }}
                    />
                  </Typography>
                  <Box
                    sx={{
                      bgcolor: 'school.secondary',
                      display: 'none',
                      height: 4,
                      width: 99
                    }}
                  />
                </Box>
              </Zoom>
            ))}
          </Box>
        </Box>
      </Box>
      <FeaturedTopics userDomains={userDomains} />
    </Box>
  )
}

export default Topics
