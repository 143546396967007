import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import PreparedCard from 'components/common/PreparedCardConnector'
import { Box } from '@mui/material'
import * as R from 'ramda'
import LoadMoreButton from 'components/common/LoadMoreButton'
import CardAnimationWrapper from 'components/common/CardAnimationWrapper'
import MasonryWrapper from 'components/common/MasonryWrapper'
import renderOrgDomain from 'components/school/getOrgDomain'
import { useGetPublicCardsQuery } from 'api/publicApi'
import ExploreBlockWrapper from 'pages/explore/components/ExploreBlockWrapper'
import { Star } from '@mui/icons-material'

const WelcomeTrendingBlock = () => {
  const [page, setPage] = useState(1)

  const org_domain = renderOrgDomain()

  const { data = [], isFetching } = useGetPublicCardsQuery(
    `org_domain=${org_domain}&page[take]=${page * 12}&sort[method]=recency`
  )

  return (
    <Box alignItems={'center'} sx={{ maxWidth: 1080, pr: 1.5, width: 1 }}>
      <ExploreBlockWrapper
        icon={<Star />}
        sx={{ ml: 'auto', mr: 'auto', width: 0.8 }}
        title={<FormattedMessage defaultMessage="New & Trending" id="/CJs/f" />}
      >
        <MasonryWrapper>
          {data.map((card, i) => (
            <CardAnimationWrapper key={i}>
              <PreparedCard
                blockTitle="New & Trending"
                card={card}
                isWelcome={true}
              />
            </CardAnimationWrapper>
          ))}
        </MasonryWrapper>
      </ExploreBlockWrapper>
      <Box display={'flex'} justifyContent={'center'}>
        {R.not(R.equals(R.length(data) % 2, NaN)) && (
          <LoadMoreButton
            handleClick={() => setPage(R.inc(page))}
            loading={isFetching}
          />
        )}
      </Box>
    </Box>
  )
}

export default WelcomeTrendingBlock
