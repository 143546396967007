import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded'
import { Chip } from '@mui/material'
import useCurrentAppType from 'hooks/useCurrentAppType'

const SocialBadge = ({ socialCount = null }) => {
  const { isVeteran } = useCurrentAppType()

  const renderLabel = () => {
    if (isVeteran)
      return (
        <FormattedMessage
          defaultMessage="{socialCount} Veterans"
          id="up0KS4"
          values={{ socialCount }}
        />
      )
    return (
      <FormattedMessage
        defaultMessage="{socialCount} Responders"
        id="rwn4bl"
        values={{ socialCount }}
      />
    )
  }

  return <Chip icon={<PeopleRoundedIcon />} label={renderLabel()} />
}

SocialBadge.propTypes = {
  socialCount: PropTypes.number
}

export default SocialBadge
