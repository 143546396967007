import PropTypes from 'prop-types'
import { FormattedMessage, useIntl } from 'react-intl'
import CalloutBox from 'components/common/CalloutBox'
import { Link } from 'react-router-dom'
import { Typography } from '@mui/material'
import { setIsCrisisModalOpen } from 'components/modals/modalSlice'
import { useDispatch } from 'react-redux'

const SubdomainCalloutBox = ({
  crisisSubdomain = false,
  topSectionCopy = null
}) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const defaultCopy = intl.formatMessage({
    defaultMessage: 'If you are seeking immediate support',
    id: 'hY33rf'
  })

  const toggleCrisis = e => {
    e.preventDefault()
    dispatch(setIsCrisisModalOpen(true))
  }

  const bottomCopy = (
    <>
      <Typography component="span" fontFamily="mark-ot-bold" variant="body1">
        <FormattedMessage
          defaultMessage="When life gets hard, your campus community is here to help."
          id="EFNW3S"
        />
      </Typography>
      <Typography component="span" id="help" sx={{ ml: 0.5 }} variant="body1">
        <FormattedMessage
          defaultMessage="{copy}, {resourceLink}."
          id="ywZQ/U"
          values={{
            copy: defaultCopy,
            resourceLink: (
              <Typography
                component={Link}
                onClick={e => toggleCrisis(e)}
                sx={{
                  textDecoration: 'underline'
                }}
                to=""
              >
                <FormattedMessage
                  defaultMessage="connect with crisis resources here"
                  id="gBYlUI"
                />
              </Typography>
            )
          }}
        />
      </Typography>
    </>
  )

  return (
    <CalloutBox
      bottomSectionCopy={crisisSubdomain ? bottomCopy : null}
      topSectionCopy={topSectionCopy}
    />
  )
}

SubdomainCalloutBox.propTypes = {
  crisisSubdomain: PropTypes.bool,
  topSectionCopy: PropTypes.string
}

export default SubdomainCalloutBox
