import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { getSSOBodyCopy } from 'pages/authentication/authenticationSlice'
import { useSelector } from 'react-redux'
import { and, equals, isNil, not, path, prop, reject } from 'ramda'
import { useSignInGoogleMutation } from 'api/authApi'
import { google } from 'icons'
import { Button } from '@mui/material'
import { useAnalytics, useLocalStorage } from 'hooks'
import GA from 'services/gaService'
import { useNavigate } from 'react-router-dom'
import { useGoogleLogin } from '@react-oauth/google'

const GoogleButton = ({
  accessToken,
  isSignUp,
  setAccessToken,
  setIsOpen,
  setSSOError,
  setSSOErrorCode,
  setUserData
}) => {
  const navigate = useNavigate()
  const { domain } = useSelector(prop('school'))
  const { sendEvent } = useAnalytics()
  const { save } = useLocalStorage()

  // Redux Selector
  const bodyCopy = useSelector(({ authentication }) =>
    getSSOBodyCopy(authentication)
  )

  // Google
  const [signInGoogle, { data, error, isError }] = useSignInGoogleMutation()

  const handleCredentialResponse = ({ access_token }) => {
    setAccessToken(access_token)
    if (isSignUp) {
      const body = {
        ...reject(isNil, bodyCopy),
        access_token,
        org_domain: domain
      }
      return signInGoogle(body)
    }
    signInGoogle({ access_token, org_domain: domain })
  }

  const handleRedirect = () => {
    if (isSignUp) return navigate('/onboarding/introduction')
    navigate('/home')
  }

  useEffect(() => {
    if (and(not(isNil(data)), path(['user', 'active_consent_given'], data))) {
      if (not(isNil(accessToken))) setAccessToken(null)
      save(prop('x-session-id', data))
      if (isSignUp) {
        sendEvent('sign-up', {
          auth_type: 'social_sso',
          validation_errors: [],
          validation_success: true
        })
        GA.click('Social SSO Signup', 'Click')
      }
      if (not(isSignUp)) {
        sendEvent('sign-in', {
          auth_type: 'social_sso',
          validation_errors: [],
          validation_success: true
        })
        GA.click('Social SSO Signin', 'Click')
      }
      setUserData(prop('user', data))
      handleRedirect()
    }
  }, [data])

  useEffect(() => {
    if (and(isError, equals(428, prop('status', error)))) {
      setIsOpen(true)
    }
  }, [isError])

  const login = useGoogleLogin({
    onError: error => {
      setSSOError(true)
      setSSOErrorCode(prop('code', error))
    },
    onSuccess: codeResponse => handleCredentialResponse(codeResponse)
  })

  return (
    <Button
      id="continue-with-google"
      onClick={login}
      startIcon={google}
      sx={{
        bgcolor: 'grey.socialSSO',
        width: 1
      }}
      variant="sso"
    >
      Continue with Google
    </Button>
  )
}

GoogleButton.propTypes = {
  accessToken: PropTypes.string,
  isSignUp: PropTypes.bool.isRequired,
  setAccessToken: PropTypes.func.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  setSSOError: PropTypes.func.isRequired,
  setSSOErrorCode: PropTypes.func.isRequired,
  setUserData: PropTypes.func.isRequired
}

GoogleButton.defaultProps = {
  accessToken: null
}

export default GoogleButton
