/* eslint-disable sort-keys */
/* eslint-disable quotes */
import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import GA from 'services/gaService'
import CardsMask from './CardsMask'
import WelcomeHeading from './WelcomeHeading'
import AccessibleModal from 'components/common/AccessibleModal'
import VideoImage from 'components/common/VideoImage'
import LogInButton from 'components/common/LogInButton'
import styles from './WelcomeSectionHero.module.scss'

import { Box, Button, Stack, Typography } from '@mui/material'
import { prop } from 'ramda'
import LockIcon from '@mui/icons-material/Lock'
import { useAnalytics, useCurrentAppType } from 'hooks'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

const WelcomeSectionHero = () => {
  const { cloudfront_domain, name } = useSelector(prop('school'))
  const [open, setOpen] = useState(false)

  const { sendEvent } = useAnalytics()

  const { isResponder, isVeteran } = useCurrentAppType()

  const getParagraph = () => {
    if (isVeteran)
      return (
        <FormattedMessage
          defaultMessage="Whether you're 12 years, 12 months or 12 days post service, Operation Veteran Strong is a useful tool just for U.S. Veterans navigating civilian life. You’ll find inspiring stories from other Veterans, information on accessing benefits, helpful self-assessments, and a wide-range of free, innovative and practical support services."
          id="KU97Fi"
        />
      )
    if (isResponder)
      return (
        <FormattedMessage
          defaultMessage="As a frontline responder, rescuing others is the job, but that job can take its toll. YOU | ResponderStrong is a free, confidential space for responders and their families to take charge of their well-being and be their best in their personal and professional lives. Create an account for full access, or <a>explore content</a> before registering."
          id="msszr4"
          values={{
            a: chunks => <Link to="/public">{chunks}</Link>
          }}
        />
      )
  }

  const baseCopy = () => (
    <>
      <Typography>
        <FormattedMessage defaultMessage="Confidential" id="o0hn4A" />
      </Typography>
      <FormattedMessage
        defaultMessage="Anything you share, search, or read is just for you. And we’ll never sell your data."
        id="/h7Gt6"
      />
      <br />
      <br />
      <Typography>
        <FormattedMessage defaultMessage="Free" id="tf1lIh" />
      </Typography>
      <FormattedMessage
        defaultMessage="No hidden costs, ads, or in-app purchases—and there never will be."
        id="NoTuP0"
      />
    </>
  )

  const reallyCopyResponder = () => (
    <Box sx={{ display: 'grid', gridGap: 8 }}>
      <Box>
        <Typography fontFamily="mark-ot-bold">
          <FormattedMessage defaultMessage="Private" id="viXE32" />
        </Typography>
        <FormattedMessage
          defaultMessage="Anything you share, search, or read is just for you. And we’ll never sell your data."
          id="/h7Gt6"
        />
      </Box>
      <Box>
        <Typography fontFamily="mark-ot-bold">
          <FormattedMessage defaultMessage="Free" id="tf1lIh" />
        </Typography>
        <FormattedMessage
          defaultMessage="No hidden costs, ads, or in-app purchases—and there never will be."
          id="NoTuP0"
        />
      </Box>
      <Box>
        <Typography fontFamily="mark-ot-bold">
          <FormattedMessage
            defaultMessage="Brought to you by people who care."
            id="hzqjpi"
          />
        </Typography>
        <FormattedMessage
          defaultMessage="The All Clear Foundation cares about the health, well-being, and success of emergency responders, healthcare professionals, and their families. YOU | ResponderStrong is our gift to you'"
          id="vIjsSP"
        />
      </Box>
    </Box>
  )

  const getReallyCopy = () => {
    if (isResponder) return reallyCopyResponder()
    return baseCopy()
  }

  const getVeteranVideo = cloudfront_url => [
    {
      background:
        '/images/welcome/video-backgrounds/veteran/static-poster-image.jpg',
      caption:
        'Veterans reflect back on a time when they reached out for help.',
      name: 'Stories of Veteran Strength and Resilience',
      url:
        'https://' +
        cloudfront_url +
        '/misc/welcome-videos/veteran/Stories-of-Veteran-Strength-and-Resilience.mp4'
    }
  ]

  const getCollectiveVideo = cloudfront_url => [
    {
      background:
        '/images/welcome/video-backgrounds/collective/collective_hero_image.jpg',
      caption: 'Collective Logo',
      name: 'Collective Logo',
      url:
        'https://' +
        cloudfront_url +
        '/misc/welcome-videos/collective/collective_welcome_video.mp4'
    }
  ]

  const video = () => {
    if (isVeteran) return getVeteranVideo(cloudfront_domain)
    return getCollectiveVideo(cloudfront_domain)
  }

  const onClose = () => {
    setOpen(false)
  }

  const reallyHandler = () => {
    setOpen(true)
    GA.click('Welcome-Hero-Really-Button', 'Click')
  }

  const sendKeenIOEvent = (element_title, click_element) => {
    sendEvent('welcome_page', {
      click_element: click_element,
      element_title: element_title,
      section: 'hero'
    })
  }

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: { xs: '1fr', lg: 'repeat(2, 1fr)' },
        pb: { xs: '20%', lg: '10%', xl: 0 },
        position: 'relative'
      }}
    >
      <Box className={styles['hero-hash-circle-container']}>
        <Box className={styles['hero-hash-circle']} />
      </Box>

      <Box
        className={styles['left-column']}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gridRow: { xs: 2, lg: 'auto' },
          p: { xs: '5%', lg: '15% 7%' }
        }}
      >
        <WelcomeHeading />
        <Typography component="p" my="24px" p={0} variant="bodyLarge">
          {getParagraph(name)}
        </Typography>

        <Box
          display="flex"
          sx={{
            gridGap: 16
          }}
        >
          <LogInButton>
            <FormattedMessage defaultMessage="Log In" id="r2Jjms" />
          </LogInButton>
          <Button
            id="get-started"
            LinkComponent={Link}
            onClick={() => {
              sendKeenIOEvent('create a new account', 'Link')
            }}
            to="/sign-up"
          >
            <FormattedMessage defaultMessage="Get Started" id="9QXm1E" />
          </Button>
        </Box>

        <AccessibleModal
          onClose={onClose}
          open={open}
          title={
            <FormattedMessage
              defaultMessage="We care about your privacy as much as you do"
              id="OjzsQL"
            />
          }
        >
          {getReallyCopy()}
        </AccessibleModal>

        <Box
          alignItems="center"
          className={styles['confidential']}
          display="flex"
          my="20px"
          position="relative"
        >
          <LockIcon />
          <Stack
            direction="row"
            sx={{
              alignItems: 'center',
              color: 'secondary.dark',
              fontFamily: 'mark-ot',
              fontSize: { xs: 18, lg: 20 },
              letterSpacing: '0.1px',
              lineHeight: '28px'
            }}
          >
            <Typography>
              <FormattedMessage
                defaultMessage="100% free & confidential."
                id="3J5QkV"
              />
            </Typography>
            <Button
              onClick={() => {
                reallyHandler()
                sendKeenIOEvent('Really?', 'Link')
              }}
              sx={{
                fontSize: { xs: 18, lg: 20 },
                ml: 0.5,
                p: 0,
                textDecoration: 'underline',
                textTransform: 'none'
              }}
              variant="text"
            >
              <FormattedMessage defaultMessage="Really." id="4JhcHA" />
            </Button>
          </Stack>

          <svg
            className={styles['confidential_arrow_svg']}
            fill="none"
            height="74"
            viewBox="0 0 38 74"
            width="38"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1 72.5C38.5 61.5 53 36 9.5 5" />
            <path
              className={styles['arrows']}
              d="M10.175 16.9918L8 4.17505L20.8168 2.00001"
            />
          </svg>
        </Box>
      </Box>

      <Box
        className={styles['right-column']}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gridRow: { xs: 1, lg: 'initial' },
          height: { xs: 'fit-content', lg: '100%' },
          width: { xs: 1, lg: '45vw' }
        }}
      >
        {isVeteran && (
          <Box p={{ xs: '8%', lg: '25% 5% 0 0', xl: '15% 5% 0 0' }}>
            <VideoImage
              background={prop('background', prop(0, video()))}
              handleKeen={sendKeenIOEvent}
              videoUrl={prop('url', prop(0, video()))}
            />
          </Box>
        )}

        {isResponder && (
          <Box display="flex" justifyContent="flex-end">
            <CardsMask position="right">
              <Box
                component="img"
                height="100%"
                src="/images/welcome/phone-images/responder-phone-hero.png"
                width="100%"
              />
            </CardsMask>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default WelcomeSectionHero
