import ConsentModal from './ConsentModal'
import { prop } from 'ramda'
import { connect } from 'react-redux'
import {
  resetState,
  setIsConsentOpen
} from 'pages/authentication/authenticationSlice'
import { setUserData } from 'store/userSlice'

const mapStateToProps = ({ authentication }) => ({
  consent: prop('consent', authentication),
  isOpen: prop('isConsentOpen', authentication),
  location: prop('location', authentication),
  modalType: prop('modalType', authentication),
  responderType: prop('responderType', authentication),
  userType: prop('userType', authentication)
})

const mapDispatchToProps = {
  resetState,
  setIsOpen: setIsConsentOpen,
  setUserData
}

export default connect(mapStateToProps, mapDispatchToProps)(ConsentModal)
