import EmailAndPassword from './EmailAndPassword'
import { prop } from 'ramda'
import { connect } from 'react-redux'
import {
  setEmail,
  setEmailError,
  setPassword,
  setPasswordError,
  setRevalidation
} from '../authenticationSlice'

const mapStateToProps = ({ authentication }) => ({
  email: prop('email', authentication),
  emailError: prop('emailError', authentication),
  password: prop('password', authentication),
  revalidation: prop('revalidation', authentication)
})

const mapDispatchToProps = {
  setEmail,
  setEmailError,
  setPassword,
  setPasswordError,
  setRevalidation
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailAndPassword)
