/* eslint-disable */
import { any, equals, isEmpty, isNil, prop } from 'ramda'
import { useSelector } from 'react-redux'
import TagManager from 'react-gtm-module'
import { useCurrentAppType } from 'hooks'

const AnalyticsComponent = () => {
  const { google_analytics_id } = useSelector(prop('school'))
  const { isVeteran } = useCurrentAppType()

  if (
    any(equals(true))([
      isNil(google_analytics_id),
      isEmpty(google_analytics_id)
    ])
  )
    return

  const tagManagerArgs = {
    gtmId: isVeteran ? 'GTM-PSTRQZK' : 'GTM-M4FVG8F'
  }

  TagManager.initialize(tagManagerArgs)

  return (
    <>
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${google_analytics_id}`}
      />
      <script
        dangerouslySetInnerHTML={{
          __html: ` window.dataLayer = window.dataLayer || []; function gtag()
        {dataLayer.push(arguments)}
        gtag('js', new Date()); gtag('config', 'GTM-ID');`
        }}
      />
    </>
  )
}

export default AnalyticsComponent
