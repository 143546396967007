import { FormattedMessage } from 'react-intl'
import { Box, Divider, Stack, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

const imageStyle = {
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  height: 70,
  width: 1
}

const SidebarFeature = () => (
  <Stack
    sx={{
      mb: 0,
      mt: 'auto',
      pb: 3,
      px: 2,
      width: 1
    }}
  >
    <Box>
      <Typography variant="overline">
        <FormattedMessage defaultMessage="Brought to you by" id="O5LK8i" />
      </Typography>
      <Box
        component={Link}
        sx={{
          background: 'url(/images/ndri-ventures.png)',
          display: 'block',
          ...imageStyle
        }}
        target="_blank"
        title="Ndri Ventures"
        to="https://ndriventures.com/"
      />
    </Box>
    <Divider />
    <Typography
      color="secondary.dark"
      component={Link}
      target="_blank"
      to="https://responderstrong.org/youresponderstrong-marketing-toolkit"
      variant="overline"
    >
      <FormattedMessage defaultMessage="Help us spread the word" id="F0vB/H" />
    </Typography>
  </Stack>
)

export default SidebarFeature
