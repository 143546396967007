import { useState } from 'react'
import {
  Autocomplete,
  Button,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import { equals, flip, prop } from 'ramda'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import { useCurrentAppType } from 'hooks'
import { useSelector } from 'react-redux'

const CountySelect = ({ location, setLocation }) => {
  const { locations } = useSelector(prop('school'))
  const { isVeteran } = useCurrentAppType()

  const [inputValue, setInputValue] = useState('')

  const mappedLocations = locations.map(({ id, name }) => ({
    id,
    label: name
  }))

  return (
    <Autocomplete
      getOptionLabel={prop('label')}
      inputValue={inputValue}
      isOptionEqualToValue={(opt, val) =>
        equals(prop('id', opt), prop('id', val))
      }
      onChange={(__, val) => setLocation(val)}
      onInputChange={flip(setInputValue)}
      options={mappedLocations}
      renderInput={params => (
        <TextField
          {...params}
          helperText={
            <Tooltip
              title={
                <Typography variant="caption">
                  {isVeteran ? (
                    <FormattedMessage
                      defaultMessage="YOU is a confidential platform. Your county is used only to personalize the resources and tools we share based on your geographic region. We will never sell or distribute your data to anyone."
                      id="q50BNL"
                    />
                  ) : (
                    <FormattedMessage
                      defaultMessage="YOU is a confidential platform. Your location is used only to personalize the resources and tools we share based on your geographic region. We will never sell or distribute your data to anyone."
                      id="b63B63"
                    />
                  )}
                </Typography>
              }
            >
              <Button
                startIcon={<InfoIcon />}
                sx={{ p: 0, textTransform: 'initial' }}
                variant="text"
              >
                {isVeteran ? (
                  <FormattedMessage
                    defaultMessage="Why do you need my county?"
                    id="5zExde"
                  />
                ) : (
                  <FormattedMessage
                    defaultMessage="Why do you need my location?"
                    id="v8A5sW"
                  />
                )}
              </Button>
            </Tooltip>
          }
          label={
            isVeteran ? (
              <FormattedMessage
                defaultMessage="What is your Colorado County?"
                id="YqDaWg"
              />
            ) : (
              <FormattedMessage
                defaultMessage="Where are you located?"
                id="Tse7aG"
              />
            )
          }
        />
      )}
      value={location}
    />
  )
}

CountySelect.propTypes = {
  location: PropTypes.shape({}),
  setLocation: PropTypes.func.isRequired
}

CountySelect.defaultProps = {
  location: null
}

export default CountySelect
