import PropTypes from 'prop-types'
import { Box } from '@mui/material'

const WelcomeSectionWrapper = ({
  children,
  toggleGrey = false,
  curveTop = false,
  curveBottom = false
}) => {
  const fillColor = '#FBFBFB'

  return (
    <Box
      sx={{
        bgcolor: toggleGrey && fillColor,
        position: 'relative',
        width: 1
      }}
    >
      {curveTop && (
        <Box
          sx={{
            '& > svg': {
              fill: fillColor,
              height: 'calc(100% * (330 / 1440))',
              width: '100%'
            },
            position: 'absolute',
            top: 0,
            width: '100%',
            zIndex: '0'
          }}
        >
          <svg
            fill="none"
            height="240"
            viewBox="0 0 1441 240"
            width="1441"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0.0917969 0C84.3231 42.8326 160.854 91.9443 278.815 107.273C477.762 133.125 586.569 35.8583 785.515 61.7106C984.461 87.5629 1065.56 209.508 1264.51 235.36C1327.06 243.488 1385.07 240.014 1440.03 232.053L1440.09 0H0.0917969Z" />
          </svg>
        </Box>
      )}
      {curveBottom && (
        <Box
          sx={{
            '& > svg': {
              fill: fillColor,
              height: 'calc(100% * (330 / 1440))',
              width: '100%'
            },
            bottom: -8,
            position: 'absolute',
            width: '100%',
            zIndex: '0'
          }}
        >
          <svg
            fill="none"
            height="330"
            viewBox="0 0 1440 330"
            width="1440"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1042.4 133.416C821.772 104.976 710.184 252.105 489.558 223.664C278.881 196.507 158.926 33.7697 1.90717e-06 0L0 330H1440C1300.51 271.092 1219.78 156.281 1042.4 133.416Z" />
          </svg>
        </Box>
      )}
      {children}
    </Box>
  )
}

WelcomeSectionWrapper.propTypes = {
  children: PropTypes.shape({}).isRequired,
  curveBottom: PropTypes.bool,
  curveTop: PropTypes.bool,
  toggleGrey: PropTypes.bool
}

export default WelcomeSectionWrapper
