import PropTypes from 'prop-types'
import GA from 'services/gaService'
import { FormattedMessage } from 'react-intl'
import { prop } from 'ramda'
import {
  Button,
  Card,
  CardContent,
  Divider,
  Skeleton,
  Typography
} from '@mui/material'
import { setIsCrisisModalOpen } from 'components/modals/modalSlice'
import { useDispatch, useSelector } from 'react-redux'

const CalloutBox = ({ topSectionCopy }) => {
  const dispatch = useDispatch()
  const { loading } = useSelector(prop('user'))

  const handleOpen = () => {
    dispatch(setIsCrisisModalOpen(true))
    GA.click('Crisis-Header-Button', 'Click')
  }

  if (loading)
    return <Skeleton height={170} variant="rectangular" width="100%" />

  const crisisLinkCopy = () => (
    <FormattedMessage defaultMessage="Crisis Resources." id="nIlxDw" />
  )

  const lifeIsHardCopy = (
    <FormattedMessage
      defaultMessage="When should you reach out for help?"
      id="YEF+7Q"
    />
  )

  const immediateSupportCopy = (
    <FormattedMessage
      defaultMessage="It's never too soon to reach out to someone who can help, or even just listen."
      id="ox8sTQ"
    />
  )

  const bottomCopy = (
    <>
      <Typography fontFamily="mark-ot-bold" sx={{ display: 'inline' }}>
        {lifeIsHardCopy}
      </Typography>
      <Typography component="span" id="help" px="2px">
        {immediateSupportCopy}
      </Typography>
      <Button
        aria-describedby="help"
        onClick={() => handleOpen()}
        sx={{
          color: 'primary.main',
          cursor: 'pointer',
          fontSize: 20,
          ml: 0.5,
          p: 0,
          pb: 0.5,
          textDecoration: 'underline',
          textTransform: 'none'
        }}
        variant="text"
      >
        {crisisLinkCopy()}
      </Button>
    </>
  )

  return (
    <>
      <Card
        component="aside"
        sx={{
          borderTop: '4px solid',
          borderTopColor: 'school.accent'
        }}
      >
        <CardContent>
          {topSectionCopy}
          <Divider />
          {bottomCopy}
        </CardContent>
      </Card>
    </>
  )
}

CalloutBox.propTypes = {
  topSectionCopy: PropTypes.string.isRequired
}

export default CalloutBox
