import { prop } from 'ramda'
import { baseQuery } from './baseQuery'

export const authApi = baseQuery.injectEndpoints({
  endpoints: ({ mutation, query }) => ({
    loggedIn: query({
      query: () => '/auth/am-i-logged-in'
    }),
    logout: mutation({
      query: () => ({
        method: 'POST',
        url: '/logout'
      })
    }),
    passwordResetEmail: mutation({
      query: body => ({
        body,
        method: 'POST',
        url: '/password/email'
      })
    }),
    resetPassword: mutation({
      query: body => ({
        body,
        method: 'POST',
        url: '/password/reset'
      })
    }),
    signIn: mutation({
      query: body => ({
        body,
        method: 'POST',
        url: `/login?org_domain=${prop('org_domain', body)}`
      }),
      transformResponse: (res, meta, req) => ({
        headers: prop('response', meta),
        req,
        res
      })
    }),
    signInFacebook: mutation({
      query: body => ({
        body,
        method: 'POST',
        url: `/auth/social/facebook?org_domain=${prop('org_domain', body)}`
      })
    }),
    signInGoogle: mutation({
      query: body => ({
        body,
        method: 'POST',
        url: `/auth/social/google?org_domain=${prop('org_domain', body)}`
      })
    }),
    signUp: mutation({
      query: body => ({
        body,
        method: 'POST',
        url: `/register?org_domain=${prop('org_domain', body)}`
      })
    })
  }),
  reducerPath: 'authApi'
})

export const {
  useLoggedInQuery,
  useLogoutMutation,
  usePasswordResetEmailMutation,
  useResetPasswordMutation,
  useSignInMutation,
  useSignInFacebookMutation,
  useSignInGoogleMutation,
  useSignUpMutation
} = authApi
