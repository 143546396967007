import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle
} from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { useSession, useCurrentAppType, useLocalStorage } from 'hooks'
import { useEffect } from 'react'
import { useLogoutMutation } from 'api/authApi'
import { and, equals, isNil, not, prop } from 'ramda'
import GA from 'services/gaService'
import { resetState } from 'store/userSlice'
import { useDispatch, useSelector } from 'react-redux'
import { googleLogout } from '@react-oauth/google'

const Logout = () => {
  const { azureLogoutUrl, sso_enabled, sso_logout_link } = useSelector(
    prop('school')
  )
  const { isVeteran } = useCurrentAppType()
  const dispatch = useDispatch()
  const { clear } = useLocalStorage()
  const { clearStorage } = useSession()

  const [logout, { isSuccess }] = useLogoutMutation()

  const handleAfter = (url = '/') => {
    GA.click('Logout', 'Click')
    clear()
    clearStorage()
    dispatch(resetState())
    googleLogout()
    window.location = url
  }

  useEffect(() => {
    if (isSuccess) {
      if (and(equals('1', sso_enabled), sso_logout_link)) {
        return handleAfter(sso_logout_link)
      }
      if (and(isVeteran, not(isNil(azureLogoutUrl)))) {
        const url = new URL(azureLogoutUrl)
        url.searchParams.set('post_logout_redirect_uri', window.location.origin)
        return handleAfter(url.href)
      }
      handleAfter()
    }
  }, [isSuccess])

  useEffect(() => {
    logout()
  }, [])

  return (
    <Dialog
      aria-labelledby="dialog-title"
      open
      PaperProps={{
        sx: {
          height: 200,
          width: 375
        }
      }}
      role="alertdialog"
    >
      <DialogTitle component="h1" id="dialog-title" tabIndex={-1}>
        <FormattedMessage defaultMessage="Logging Out..." id="u6iQRM" />
      </DialogTitle>
      <DialogContent
        sx={{
          alignItems: 'center',
          color: 'school.secondary',
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <CircularProgress color="inherit" />
      </DialogContent>
    </Dialog>
  )
}

export default Logout
