/* eslint-disable sort-keys */
import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { quotation_mark } from '../../../icons'
import WelcomeNumberNav from './WelcomeNumberNav'
import styles from './WelcomeSectionQuotes.module.scss'
import { path, prop } from 'ramda'
import { Box, Typography, useTheme } from '@mui/material'
import { useAnalytics } from 'hooks'
import { useSelector } from 'react-redux'

const WelcomeSectionQuotes = () => {
  const { palette } = useTheme()
  const { cloudfront_domain } = useSelector(prop('school'))
  const { sendEvent } = useAnalytics()

  const getBusinessImages = () => {
    return [
      {
        background: '/images/welcome/quotes-backgrounds/1-quote.jpg',
        caption:
          '"YOU has been a great space for me to get insights that I wouldn\'t find anywhere else. It\'s helped me with my work, my family life, and overall stress management."',
        captionAuthor: '',
        name: 'Pic 1'
      },
      {
        background: '/images/welcome/quotes-backgrounds/2-stat.png',
        caption: '76% of users said that YOU helped to manage their stress.',
        captionAuthor: '',
        name: 'Pic 2'
      },
      {
        background: '/images/welcome/quotes-backgrounds/3-quote.jpg',
        caption:
          '"YOU helped me set goals for myself in the areas of finances, career, and my health, develop better time managment skills, and help me be a better team member."',
        captionAuthor: '',
        name: 'Pic 3'
      }
    ]
  }

  const quotes = getBusinessImages(cloudfront_domain)
  const [currentQuote, setCurrentQuote] = useState(0)

  const handleNav = index => {
    if (index !== currentQuote) {
      setCurrentQuote(index)
    }
  }

  const sendKeenIOEvent = (element_title, click_element) => {
    sendEvent('welcome_page', {
      click_element: click_element,
      element_title: element_title,
      section: 'stories'
    })
  }

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: { xs: '1fr', lg: 'repeat(2, auto)' },
        position: 'relative',
        pt: { xs: 14, md: '30%', lg: 14 }
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          gridColumn: { xs: 'initial', lg: 2 },
          gridRow: { xs: 2, lg: 1 },
          width: { xs: 1, lg: '45vw' }
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            p: { xs: '10% 3%', lg: 0, xl: '0 10% 0 0' },
            width: { xs: 1, lg: '80%' }
          }}
        >
          <Typography color="primary.main" variant="h2">
            <FormattedMessage
              defaultMessage="How others find balance with YOU..."
              id="ftGSdH"
            />
          </Typography>

          <WelcomeNumberNav
            ariaLablel="move to next quote"
            caption={prop(currentQuote, quotes).caption}
            captionBoxStyles={{
              left: { xs: 'initial', lg: -150 },
              width: { xs: 'initial', lg: '124%' }
            }}
            currentItem={currentQuote}
            handleClick={(e, index) => {
              e.preventDefault()
              handleNav(index)
              sendKeenIOEvent('Quote Nav ' + (index + 1), 'Button')
            }}
            items={quotes}
          />
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          gridColumn: { xs: 'initial', lg: 1 },
          gridRow: { xs: 1, lg: 'initial' },
          position: 'relative',
          width: { xs: 1, lg: '45vw' }
        }}
      >
        <Box className={styles['hash-square-container']}>
          <Box className={styles['hash-square']} />
        </Box>

        <Box
          sx={{
            margin: '15% auto',
            position: 'relative',
            width: '70%'
          }}
        >
          <Box
            sx={{
              '& > svg': {
                fill: path(['primary', 'main'], palette)
              },
              position: 'absolute',
              top: -40,
              zIndex: 2
            }}
          >
            {quotation_mark}
          </Box>

          <Box
            sx={{
              bgcolor: 'school.secondary',
              float: 'right',
              maskImage: 'url(/images/welcome/mask-quotes.svg)',
              maskSize: 'contain',
              maskRepeat: 'no-repeat',
              maskPosition: 'top center',
              mr: '-10px',
              mt: '3%',
              pt: '100%',
              width: 1
            }}
          />
          <Box
            sx={{
              background: `url(${prop(currentQuote, quotes).background})`,
              backgroundSize: 'cover',
              backgroundPosition: 'top center',
              width: 1,
              position: 'absolute',
              right: '-25px',
              top: '0',
              pt: '100%'
            }}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default WelcomeSectionQuotes
