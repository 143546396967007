/* eslint-disable sort-keys */
import { Stack, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { useCurrentAppType } from 'hooks'
import WelcomeNavItem from 'pages/dashboard/components/WelcomeNavItem'

const vetTopics = [
  {
    image: '/images/ovs/local-vet-resources.png',
    internalURL: '/public/resources',
    title: 'Local Veteran Resources'
  },
  {
    image: '/images/ovs/veteran-stories.png',
    internalURL: '/public/stories',
    title: 'Hear Veteran Stories'
  },
  {
    image: '/images/ovs/about-ovs.png',
    internalURL: '/about-you',
    title: 'Learn About OVS'
  }
]

const responderTopics = [
  {
    image: '/images/responder/responder-resources.png',
    internalURL: '/public/resources',
    title: 'Responder Resources'
  },
  {
    image: '/images/responder/rs-stories.png',
    internalURL: '/public/stories',
    title: 'Emergency Responder Stories'
  },
  {
    image: '/images/responder/about-rs.png',
    internalURL: '/about-you',
    title: 'Learn About ResponderStrong'
  }
]

const CTA = () => {
  const { isVeteran } = useCurrentAppType()

  const renderTopics = isVeteran ? vetTopics : responderTopics

  return (
    <Stack
      alignItems={'center'}
      mt={5}
      pl={2}
      pr={2}
      spacing={5}
      textAlign={'center'}
    >
      <Typography
        color="primary"
        fontFamily={'mark-ot-bold'}
        fontSize={{ xs: 20, md: 29 }}
        variant="h2"
      >
        <FormattedMessage
          defaultMessage="Not ready to sign up? Start exploring without an account."
          id="gG1rzQ"
        />
      </Typography>
      <Stack
        direction="row"
        flexWrap={'wrap'}
        height={{ lg: 380 }}
        justifyContent={'center'}
        spacing={0}
      >
        {renderTopics.map(({ image, internalURL, title }) => (
          <WelcomeNavItem
            image={image}
            internalUrl={internalURL}
            key={title}
            title={title}
          ></WelcomeNavItem>
        ))}
      </Stack>
    </Stack>
  )
}

export default CTA
