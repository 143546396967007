/* eslint-disable sort-keys */
/* eslint-disable quotes */
import { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import GA from 'services/gaService'
import AccessibleModal from 'components/common/AccessibleModal'
import LogInButton from 'components/common/LogInButton'
import styles from './WelcomeSectionHero.module.scss'

import { Box, Button, Stack, Typography } from '@mui/material'
import { prop } from 'ramda'
import LockIcon from '@mui/icons-material/Lock'
import { useAnalytics, useCurrentAppType } from 'hooks'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import VideoHero from './VideoHero'
import { ArrowDownward } from '@mui/icons-material'

const WelcomeSectionBanner = () => {
  const { name } = useSelector(prop('school'))
  const [open, setOpen] = useState(false)

  const { sendEvent } = useAnalytics()

  const { isResponder, isVeteran } = useCurrentAppType()

  const [urlSlug, setUrlSlug] = useState('')

  useEffect(() => {
    if (isResponder) {
      setUrlSlug('OFD-RS-Backdrop.mp4')
    } else {
      setUrlSlug('OFD-OVS-Backdrop.mp4')
    }
  }, [isResponder, isVeteran])

  const getParagraph = () => {
    if (isVeteran)
      return (
        <FormattedMessage
          defaultMessage="Whether you're 12 years, 12 months or 12 days post service, Operation Veteran Strong is a useful tool just for U.S. Veterans navigating civilian life. You’ll find inspiring stories from other Veterans, information on accessing benefits, helpful self-assessments, and a wide-range of free, innovative and practical support services."
          id="KU97Fi"
        />
      )
    if (isResponder)
      return (
        <FormattedMessage
          defaultMessage="As a frontline responder, rescuing others is the job, but that job can take its toll. YOU | ResponderStrong is a free, confidential space for responders and their families to take charge of their well-being and be their best in their personal and professional lives."
          id="bgzmKm"
          values={{
            a: chunks => <Link to="/public">{chunks}</Link>
          }}
        />
      )
  }

  const getTitle = () => {
    if (isVeteran)
      return (
        <FormattedMessage
          defaultMessage="Welcome to Operation Veteran Strong"
          id="7ugMtn"
        />
      )
    if (isResponder)
      return (
        <FormattedMessage
          defaultMessage="An essential tool for essential work."
          id="gc/Ebk"
          values={{
            a: chunks => <Link to="/public">{chunks}</Link>
          }}
        />
      )
  }

  const baseCopy = () => (
    <>
      <Typography>
        <FormattedMessage defaultMessage="Confidential" id="o0hn4A" />
      </Typography>
      <FormattedMessage
        defaultMessage="Anything you share, search, or read is just for you. And we’ll never sell your data."
        id="/h7Gt6"
      />
      <br />
      <br />
      <Typography>
        <FormattedMessage defaultMessage="Free" id="tf1lIh" />
      </Typography>
      <FormattedMessage
        defaultMessage="No hidden costs, ads, or in-app purchases—and there never will be."
        id="NoTuP0"
      />
    </>
  )

  const reallyCopyResponder = () => (
    <Box sx={{ display: 'grid', gridGap: 8 }}>
      <Box>
        <Typography fontFamily="mark-ot-bold">
          <FormattedMessage defaultMessage="Private" id="viXE32" />
        </Typography>
        <FormattedMessage
          defaultMessage="Anything you share, search, or read is just for you. And we’ll never sell your data."
          id="/h7Gt6"
        />
      </Box>
      <Box>
        <Typography fontFamily="mark-ot-bold">
          <FormattedMessage defaultMessage="Free" id="tf1lIh" />
        </Typography>
        <FormattedMessage
          defaultMessage="No hidden costs, ads, or in-app purchases—and there never will be."
          id="NoTuP0"
        />
      </Box>
      <Box>
        <Typography fontFamily="mark-ot-bold">
          <FormattedMessage
            defaultMessage="Brought to you by people who care."
            id="hzqjpi"
          />
        </Typography>
        <FormattedMessage
          defaultMessage="The All Clear Foundation cares about the health, well-being, and success of emergency responders, healthcare professionals, and their families. YOU | ResponderStrong is our gift to you'"
          id="vIjsSP"
        />
      </Box>
    </Box>
  )

  const getReallyCopy = () => {
    if (isResponder) return reallyCopyResponder()
    return baseCopy()
  }

  const onClose = () => {
    setOpen(false)
  }

  const reallyHandler = () => {
    setOpen(true)
    GA.click('Welcome-Hero-Really-Button', 'Click')
  }

  const sendKeenIOEvent = (element_title, click_element) => {
    sendEvent('welcome_page', {
      click_element: click_element,
      element_title: element_title,
      section: 'hero'
    })
  }

  return (
    <VideoHero urlSlug={urlSlug}>
      <Stack
        alignItems={'center'}
        maxWidth={{ xs: '91%', md: '80%' }}
        mt={{ xs: 17 }}
        spacing={{ xs: 3, md: 5 }}
        textAlign={'center'}
      >
        <Typography
          component="h1"
          fontSize={{ xs: 36, md: 36, lg: 60 }}
          lineHeight={{ xs: 1, md: 1 }}
          sx={{
            color: 'white',
            fontFamily: 'mark-ot-bold',
            fontWeight: 700,
            zIndex: 2
          }}
        >
          {getTitle()}
        </Typography>
        <Typography
          color={'white'}
          component="p"
          fontSize={{ xs: 16, md: 20 }}
          lineHeight={'28px'}
          my="20px"
          p={0}
          variant="bodyLarge"
        >
          {getParagraph(name)}
        </Typography>
        <Stack direction={'row'} spacing={2}>
          <Button
            id="get-started"
            LinkComponent={Link}
            onClick={() => {
              sendKeenIOEvent('create a new account', 'Link')
            }}
            sx={{ lineHeight: 1.5 }}
            to="/sign-up"
          >
            <FormattedMessage
              defaultMessage="Create a new account "
              id="Edl1Y8"
            />
          </Button>
          <LogInButton isWelcome={true} isWhite>
            <FormattedMessage defaultMessage="LogIn" id="mFeASR" />
          </LogInButton>
        </Stack>
        <AccessibleModal
          onClose={onClose}
          open={open}
          sx={{ zIndex: 2 }}
          title={
            <FormattedMessage
              defaultMessage="We care about your privacy as much as you do"
              id="OjzsQL"
            />
          }
        >
          {getReallyCopy()}
        </AccessibleModal>
        <Box
          alignItems="center"
          className={styles['confidential']}
          display="flex"
          my="20px"
          position="relative"
        >
          <LockIcon sx={{ color: 'white' }} />
          <Stack
            direction="row"
            sx={{
              alignItems: 'center',
              color: 'white',
              fontFamily: 'mark-ot',
              letterSpacing: '0.1px',
              lineHeight: '28px',
              ml: 1
            }}
          >
            <Typography sx={{ color: 'white', fontSize: { xs: 14 } }}>
              <FormattedMessage
                defaultMessage="100% free & confidential."
                id="3J5QkV"
              />
            </Typography>
            <Button
              onClick={() => {
                reallyHandler()
                sendKeenIOEvent('Really?', 'Link')
              }}
              sx={{
                color: 'white',
                fontSize: { xs: 14 },
                ml: 0.5,
                p: 0,
                textDecoration: 'underline',
                textTransform: 'none'
              }}
              variant="text"
            >
              <FormattedMessage defaultMessage="Really." id="4JhcHA" />
            </Button>
          </Stack>

          <svg
            className={styles['confidential_arrow_svg']}
            fill="none"
            height="74"
            viewBox="0 0 38 74"
            width="38"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1 72.5C38.5 61.5 53 36 9.5 5" />
            <path
              className={styles['arrows']}
              d="M10.175 16.9918L8 4.17505L20.8168 2.00001"
            />
          </svg>
        </Box>
        <Stack alignItems={'center'} spacing={1}>
          <Typography
            sx={{
              color: 'white',
              fontSize: { xs: 14 },
              textTransfor: 'uppercase'
            }}
          >
            <FormattedMessage defaultMessage="Scroll to Discover" id="mW24Fs" />
          </Typography>
          <ArrowDownward sx={{ color: 'white', fontSize: 25 }} />
        </Stack>
      </Stack>
    </VideoHero>
  )
}

export default WelcomeSectionBanner
