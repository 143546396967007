import { includes, equals } from 'ramda'
import { useSelector } from 'react-redux'

const useCurrentDomain = () => {
  const { domain } = useSelector(({ school }) => school)
  const isDev = equals(process.env.REACT_APP_PUBLIC_APP_ENV, 'development')
  const currentDomain = isDev ? process.env.REACT_APP_PUBLIC_ORG_DOMAIN : domain

  const isVeteran = includes('veteran', currentDomain)

  return {
    currentDomain,
    isDev,
    isVeteran
  }
}

export default useCurrentDomain
