import { prop } from 'ramda'
import { Stack } from '@mui/material'
import useCurrentAppType from 'hooks/useCurrentAppType'

import CTA from './components/CTA'
import Topics from 'pages/public/components/explore/Topics'
import WelcomeSectionBanner from './components/WelcomeSectionBanner'
import WelcomeTrendingBlock from './components/WelcomeTrendingBlock'
import WelcomeAbout from './components/WelcomeAbout'

const Welcome = () => {
  const { isVeteran } = useCurrentAppType()

  const welcomeWidgets = {
    about: <WelcomeAbout key="about" />,
    banner: <WelcomeSectionBanner key="banner" />,
    cta: <CTA key="cta" />,
    featured: <Topics key="topics" />,
    trending: <WelcomeTrendingBlock key="trending" />
  }

  // each app type needs a welcome page list
  const widgetList = () => {
    if (isVeteran) return ['banner', 'cta', 'featured', 'trending', 'about']
    return ['banner', 'cta', 'featured', 'trending', 'about']
  }

  return (
    <Stack
      alignItems={'center'}
      direction="column"
      position="relative"
      spacing={5}
      sx={{
        bgcolor: 'white',
        mb: 5,
        overflowX: 'hidden'
      }}
    >
      {widgetList().map(widgetName => prop(widgetName, welcomeWidgets))}
    </Stack>
  )
}

export default Welcome
