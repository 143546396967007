import { all, and, equals, isNil, not, any, path } from 'ramda'
import { useEffect } from 'react'
import useLocalStorage from 'hooks/useLocalStorage'
import { onAuthRoute, onPublicRoute } from 'utils/route-helpers'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

const privateRoutes = [
  'home',
  'explore',
  'self-checks',
  'goals',
  'stats-dashboard',
  'resources',
  'tips',
  'favorites',
  'article',
  'onboarding',
  'profile',
  'resources-near-me',
  'stories',
  'succeed',
  'matter',
  'thrive'
]

// This can be our new route and auth checker
// If logged in then direct where neede
// else move them out
const AuthorizationHandler = () => {
  const push = useNavigate()
  const { pathname } = useLocation()
  const { clear } = useLocalStorage()
  const id = useSelector(path(['user', 'id']))
  const isSuccess = useSelector(path(['user', 'isSuccess']))

  const onPrivateRoute = all(equals(true))([
    not(equals(pathname, '/')),
    not(onPublicRoute(pathname)),
    not(onAuthRoute(pathname)),
    privateRoutes.some(route => pathname.includes(route))
  ])

  const onRouteToClearStorage = any(equals(true))([
    equals(pathname, '/'),
    onAuthRoute(pathname)
  ])

  useEffect(() => {
    if (onRouteToClearStorage) {
      clear()
    }
  }, [onRouteToClearStorage])

  // After logging in
  // once user data has been fetched and there is a redirect route
  // push the user to redirected route
  // reset redirect to null
  const isAuthRoute = onAuthRoute(pathname)
  useEffect(() => {
    const redirect = localStorage.getItem('redirect')
    if (
      all(equals(true))([
        not(isNil(redirect)),
        not(isNil(id)),
        not(isAuthRoute)
      ])
    ) {
      push(redirect)
      localStorage.removeItem('redirect')
    }
  }, [id, isAuthRoute])

  useEffect(() => {
    if (and(equals(pathname, '/'), not(isNil(id)))) {
      push('/home')
    }
  }, [id, pathname])

  // If we have fetched user and user is null or empty
  // on a private route
  // redirect back to sign in page with redirect
  // This is for the odd times when the user returns nothing
  useEffect(() => {
    if (all(equals(true))([isNil(id), onPrivateRoute, isSuccess])) {
      clear()
      push('/sign-in')
      localStorage.setItem('redirect', pathname)
    }
  }, [id, onPrivateRoute, isSuccess])
}

export default AuthorizationHandler
