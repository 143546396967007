import { useState, useEffect } from 'react'

const ScrollInView = (ref, debug = false) => {
  const [inView, setInView] = useState(false)

  const scrollHandler = () => {
    if (ref.current == null || ref.current.offsetParent == null) return
    const windowHeight = window.innerHeight
    const inViewOffset = -innerHeight / 2 //-500;
    const scrollY = window.scrollY
    const offsetTop = ref.current.offsetParent.offsetTop

    if (scrollY + windowHeight > offsetTop - inViewOffset && !inView)
      setInView(true)

    if (debug)
      console.log({
        inView: inView,
        inViewOffset: inViewOffset,
        offsetTop,
        scrollY,
        windowHeight
      })
  }

  useEffect(() => {
    scrollHandler()
    window.addEventListener('scroll', scrollHandler)
    return () => window.removeEventListener('scroll', scrollHandler)
  }, [])

  return inView
}

export default ScrollInView
