import { FormattedMessage } from 'react-intl'
import { Typography } from '@mui/material'
import { useCurrentAppType } from 'hooks'

const WelcomeHeading = () => {
  const { isVeteran } = useCurrentAppType()

  return (
    <Typography
      sx={{
        // eslint-disable-next-line sort-keys
        fontSize: { xs: 32, md: 49, xl: 60 },
        // eslint-disable-next-line sort-keys
        lineHeight: { xs: '38px', md: 'initial' }
      }}
      variant="h1"
    >
      {isVeteran ? (
        <FormattedMessage
          defaultMessage="Our mission: to be there for Veterans like they were for US."
          id="OOJqIZ"
        />
      ) : (
        <FormattedMessage
          defaultMessage="An essential tool for essential work."
          id="gc/Ebk"
        />
      )}
    </Typography>
  )
}

export default WelcomeHeading
