import PropTypes from 'prop-types'
import { Button } from '@mui/material'
import { and, defaultTo, isEmpty, isNil, not } from 'ramda'
import { Link } from 'react-router-dom'
import useAnalytics from 'hooks/useAnalytics'
import { useSelector } from 'react-redux'

const LogInButton = ({
  children,
  icon = null,
  isWelcome = false,
  isWhite = false
}) => {
  const { sso_link_two, sso_link } = useSelector(({ school }) => school)
  const { sendEvent } = useAnalytics()

  const getSignInUrl = () => {
    const ssoEnabled = defaultTo(
      false,
      and(not(isNil(sso_link)), not(isEmpty(sso_link)))
    )

    const ssoMultipleIdps = not(isNil(sso_link_two))
    if (and(ssoEnabled, not(ssoMultipleIdps))) return sso_link

    return '/sign-in'
  }

  const sendClickEvent = () => {
    if (isWelcome) {
      sendEvent('welcome_page', {
        block_title: 'Welcome Section',
        click_element: 'Link',
        element_title: 'Log In',
        path: window.location.pathname
      })
    } else {
      sendEvent('block_interactions', {
        block_title: 'Logged Out Header',
        click_element: 'Link',
        element_title: 'Log In',
        path: window.location.pathname
      })
    }
  }

  return (
    <Button
      LinkComponent={Link}
      onClick={() => sendClickEvent()}
      startIcon={icon}
      sx={{
        borderColor: isWhite ? 'white' : 'initial',
        color: isWhite ? 'white' : 'initial',
        lineHeight: 1.5,
        p: 1.5
      }}
      to={getSignInUrl()}
      variant="outlined"
    >
      {children}
    </Button>
  )
}

LogInButton.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.node,
  isWelcome: PropTypes.bool,
  isWhite: PropTypes.bool
}

export default LogInButton
