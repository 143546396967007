import ResponderSelect from './ResponderSelect'
import { prop } from 'ramda'
import { connect } from 'react-redux'
import { setResponderType } from 'pages/authentication/authenticationSlice'

const mapStateToProps = ({ authentication }) => ({
  responderType: prop('responderType', authentication)
})

const mapDispatchToProps = {
  setResponderType
}

export default connect(mapStateToProps, mapDispatchToProps)(ResponderSelect)
