import {
  Box,
  Button,
  IconButton,
  Paper,
  Slide,
  Stack,
  Typography
} from '@mui/material'

import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'

import { FormattedMessage } from 'react-intl'
import { Link, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import useAnalytics from 'hooks/useAnalytics'
import useCurrentAppType from 'hooks/useCurrentAppType'
import { or } from 'ramda'

const CTABanner = ({ open, setWasShownBanner }) => {
  const { isVeteran } = useCurrentAppType()
  const { sendEvent } = useAnalytics()
  const { pathname } = useLocation()

  const isSignInOrUp = or(
    pathname.includes('sign-in'),
    pathname.includes('sign-up')
  )

  return (
    <Slide direction="down" in={isSignInOrUp ? false : open}>
      <Paper
        elevation={3}
        sx={{
          borderBottomLeftRadius: 20,
          borderBottomRightRadius: 20,
          boxShadow: 3,
          left: 0,
          position: 'absolute',
          top: 67,
          width: 1,
          zIndex: 1
        }}
      >
        <Stack
          direction="row"
          sx={{
            alignItems: 'center',
            bgcolor: 'school.accent',
            justifyContent: 'space-between',
            px: 3,
            py: 1.5
          }}
        >
          <Button
            endIcon={<ArrowForwardRoundedIcon />}
            LinkComponent={Link}
            onClick={() => {
              sendEvent('block_interactions', {
                block_title: 'OFD Dropdown Banner',
                click_element: 'Link',
                element_title: 'Join the Community CTA',
                path: window.location.pathname
              })
              setWasShownBanner(true)
            }}
            sx={{
              color: 'school.buttonFontColor',
              px: 0
            }}
            to="/sign-up"
            variant="cta"
          >
            {isVeteran ? (
              <FormattedMessage
                defaultMessage="Join the Operation Veteran Strong Community"
                id="D5+q/n"
              />
            ) : (
              <FormattedMessage
                defaultMessage="Sign-up for Full Access"
                id="pcqCvQ"
              />
            )}
          </Button>
          <IconButton
            // This prevents the banner from
            // showing every route after closing it
            onClick={() => {
              sendEvent('block_interactions', {
                block_title: 'OFD Dropdown Banner',
                click_element: 'Link',
                element_title: 'Close',
                path: window.location.pathname
              })
              setWasShownBanner(true)
            }}
          >
            <CloseRoundedIcon />
          </IconButton>
        </Stack>
        <Box
          sx={{
            bgcolor: 'white',
            borderBottomLeftRadius: 20,
            borderBottomRightRadius: 20,
            px: 3,
            py: 2
          }}
        >
          <Typography color="primary.main" component="span">
            <FormattedMessage
              defaultMessage="Sign-up for a free, confidential account for personalized content and resources near you. Have questions? Visit our <a>FAQ page.</a>"
              id="0q+Eml"
              values={{
                a: chunks => (
                  <Typography
                    component={Link}
                    onClick={() =>
                      sendEvent('block_interactions', {
                        block_title: 'OFD Dropdown Banner',
                        click_element: 'Link',
                        element_title: 'FAQ',
                        path: window.location.pathname
                      })
                    }
                    sx={{
                      color: 'primary.main',
                      textDecoration: 'underline'
                    }}
                    to="/about-you"
                  >
                    {chunks}
                  </Typography>
                )
              }}
            />
          </Typography>
        </Box>
      </Paper>
    </Slide>
  )
}

CTABanner.propTypes = {
  open: PropTypes.bool.isRequired,
  setWasShownBanner: PropTypes.func.isRequired
}

export default CTABanner
