import FacebookButton from './FacebookButton'
import { connect } from 'react-redux'
import { setUserData } from 'store/userSlice'
import {
  setAccessToken,
  setIsConsentOpen,
  setModalType,
  setSSOError,
  setSSOErrorCode
} from 'pages/authentication/authenticationSlice'
import { prop } from 'ramda'

const mapStateToProps = ({ authentication }) => ({
  accessToken: prop('accessToken', authentication)
})

export default connect(mapStateToProps, {
  setAccessToken,
  setIsOpen: setIsConsentOpen,
  setModalType,
  setSSOError,
  setSSOErrorCode,
  setUserData
})(FacebookButton)
