import PropTypes from 'prop-types'
import { FormattedMessage, useIntl } from 'react-intl'
import { excerpt } from 'utils/helpers'

import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  Stack,
  Typography
} from '@mui/material'
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded'
import { useCurrentAppType } from 'hooks'
import { append, findIndex, gt, prop, propEq, without } from 'ramda'
import { setSelectedGoals } from './onboardingSlice'
import { useDispatch, useSelector } from 'react-redux'

const GoalCard = ({ goal }) => {
  const { isResponder } = useCurrentAppType()
  const intl = useIntl()
  const dispatch = useDispatch()
  const { selectedGoals } = useSelector(prop('onboarding'))

  const selected = selectedGoals.some(propEq(prop('id', goal), 'id'))

  const appUser = () => {
    if (isResponder)
      return <FormattedMessage defaultMessage="Responders" id="1fEx2F" />
    return <FormattedMessage defaultMessage="Veterans" id="WmWRUu" />
  }

  const handleSelection = () => {
    const found = findIndex(propEq(prop('id', goal), 'id'), selectedGoals)
    if (gt(found, -1)) {
      dispatch(setSelectedGoals(without([goal], selectedGoals)))
      return
    }
    dispatch(setSelectedGoals(append(goal, selectedGoals)))
  }

  const { participants, steps, title } = goal

  return (
    <Card
      sx={{
        borderTop: '3px solid',
        borderTopColor: 'school.accent'
      }}
    >
      <CardHeader
        disableTypography
        subheader={
          <Stack spacing={1}>
            <Typography minHeight="52px" variant="h5">
              {/* Limiting title length to keep cards looking even */}
              {excerpt(title, 30)}
            </Typography>
            <Chip
              color="secondary"
              icon={<PeopleRoundedIcon />}
              label={
                <FormattedMessage
                  defaultMessage="{count} {appUser} Participating"
                  id="VP57RC"
                  values={{
                    appUser: appUser(),
                    count: participants
                  }}
                />
              }
            />
          </Stack>
        }
        sx={{
          pb: 0
        }}
        title={
          <Typography color="secondary.dark" variant="overline">
            <FormattedMessage defaultMessage="Suggested Goal" id="NTzXZv" />
          </Typography>
        }
      />
      <CardContent sx={{ pt: 0 }}>
        <Divider />
        <Stack justifyContent="space-between">
          <Typography variant="overline">
            <FormattedMessage defaultMessage="First Step" id="qxKWTO" />
          </Typography>
          <Typography mb="16px" textAlign="left" variant="body2">
            {/* Limiting copy length to keep cards looking even */}
            {excerpt(steps[0], 48)}
          </Typography>
        </Stack>
        <CardActions variant="center">
          <Button
            aria-label={
              selected
                ? intl.formatMessage({ defaultMessage: 'saved', id: 'dxFzER' })
                : intl.formatMessage({
                    defaultMessage: 'not saved',
                    id: '80shc5'
                  })
            }
            fullWidth
            onClick={handleSelection}
          >
            {selected ? (
              <FormattedMessage defaultMessage="Saved" id="fsB/4p" />
            ) : (
              <FormattedMessage defaultMessage="Save This Goal" id="YWKw0f" />
            )}
          </Button>
        </CardActions>
      </CardContent>
    </Card>
  )
}

GoalCard.propTypes = {
  goal: PropTypes.shape({
    participants: PropTypes.number,
    steps: PropTypes.arrayOf(PropTypes.string),
    title: PropTypes.string
  }).isRequired
}

export default GoalCard
