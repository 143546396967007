import { useMemo } from 'react'
import steps from './Steps'
import { not, or, prop, propEq, reject } from 'ramda'
import { useSelector } from 'react-redux'

const useGetSteps = () => {
  const { is_secondary_user } = useSelector(({ user }) => user)
  const { enable_preset_goals } = useSelector(prop('school'))

  const stepsToRender = useMemo(() => {
    let copy = reject(propEq('wellpower', 'name'), steps)
    if (or(is_secondary_user, not(enable_preset_goals)))
      copy = reject(propEq('goals', 'name'), copy)
    return copy
  }, [is_secondary_user, enable_preset_goals])

  return stepsToRender
}

export default useGetSteps
