import { useLocation, useNavigate } from 'react-router-dom'
import useCurrentAppType from './useCurrentAppType'
import { all, equals, includes, not } from 'ramda'

const useHandlePublicRoutes = () => {
  const { isVeteran, isResponder } = useCurrentAppType()
  const navigate = useNavigate()
  const pathname = useLocation()

  if (
    all(equals(true))([
      includes('/public', pathname),
      not(isVeteran),
      not(isResponder)
    ])
  ) {
    navigate('/')
  }
}

export default useHandlePublicRoutes
