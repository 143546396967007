import { createSelector, createSlice } from '@reduxjs/toolkit'
import { assoc, defaultTo, prop, toLower } from 'ramda'

const initialState = {
  accessToken: null,
  consent: false,
  email: null,
  emailError: false,
  isConsentOpen: false,
  location: null,
  modalType: 'google',
  password: null,
  passwordError: false,
  responderType: null,
  revalidation: null,
  role: 'student',
  ssoError: false,
  ssoErrorCode: null,
  userType: null
}

const authenticationSlice = createSlice({
  initialState,
  name: 'authentication',
  reducers: {
    resetState: () => {
      return initialState
    },
    setAccessToken: (state, { payload }) =>
      assoc('accessToken', payload, state),
    setConsent: (state, { payload }) => assoc('consent', payload, state),
    setEmail: (state, { payload }) => assoc('email', payload, state),
    setEmailError: (state, { payload }) => assoc('emailError', payload, state),
    setIsConsentOpen: (state, { payload }) =>
      assoc('isConsentOpen', payload, state),
    setLocation: (state, { payload }) => assoc('location', payload, state),
    setModalType: (state, { payload }) => assoc('modalType', payload, state),
    setPassword: (state, { payload }) => assoc('password', payload, state),
    setPasswordError: (state, { payload }) =>
      assoc('passwordError', payload, state),
    setResponderType: (state, { payload }) =>
      assoc('responderType', payload, state),
    setRevalidation: (state, { payload }) =>
      assoc('revalidation', payload, state),
    setRole: (state, { payload }) => assoc('role', payload, state),
    setSSOError: (state, { payload }) => assoc('ssoError', payload, state),
    setSSOErrorCode: (state, { payload }) =>
      assoc('ssoErrorCode', payload, state),
    setUserType: (state, { payload }) => assoc('userType', payload, state)
  }
})

export const {
  resetState,
  setAccessToken,
  setConsent,
  setEmail,
  setEmailError,
  setIsConsentOpen,
  setLocation,
  setModalType,
  setPassword,
  setPasswordError,
  setResponderType,
  setRevalidation,
  setRole,
  setSSOError,
  setSSOErrorCode,
  setUserType
} = authenticationSlice.actions

const selfState = state => state

export const getBodyCopy = createSelector(
  selfState,
  ({ consent, email, location, password, responderType, userType }) => ({
    agree_to_terms: consent,
    auth_type: 'social_sso',
    email: email ? toLower(email) : null,
    location_id: prop('id', location),
    plaintext_password: password,
    roleType: responderType,
    user_type: defaultTo('primary', userType)
  })
)

export const getSSOBodyCopy = createSelector(
  selfState,
  ({ accessToken, consent, location, responderType, userType }) => ({
    access_token: accessToken,
    agree_to_terms: consent,
    auth_type: 'social_sso',
    location_id: prop('id', location),
    roleType: responderType,
    user_type: defaultTo('primary', userType)
  })
)

export const getBasicBodyCopy = createSelector(
  selfState,
  ({ consent, email, location, password, responderType, userType }) => ({
    agree_to_terms: consent,
    auth_type: 'traditional',
    email: email ? toLower(email) : null,
    location_id: prop('id', location),
    plaintext_password: password,
    role_type: responderType,
    user_type: defaultTo('primary', userType)
  })
)

export default authenticationSlice.reducer
