import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { AppBar, Box, Button, IconButton, Toolbar } from '@mui/material'
import MenuRoundedIcon from '@mui/icons-material/MenuRounded'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import { Link, useLocation } from 'react-router-dom'
import { not, isNil, equals, and, inc, gte, any, prop, all } from 'ramda'
import { useCurrentAppType } from 'hooks'
import useAnalytics from 'hooks/useAnalytics'
import { useSelector } from 'react-redux'

import CrisisButton from '../common/CrisisButton'
import ProfileDropdown from './ProfileDropdown'
import LogInButton from '../common/LogInButton'
import LanguageSelect from '../language/LanguageSelect'
import CTABanner from './CTABanner'

const Header = ({ isOpen, setIsOpen }) => {
  const { logoImage } = useSelector(prop('school'))
  const { id } = useSelector(prop('user'))

  const { sendEvent } = useAnalytics()
  const { pathname } = useLocation()
  const { isVeteran, isResponder } = useCurrentAppType()

  const [pageCount, setPageCount] = useState(0)
  const [wasShownBanner, setWasShownBanner] = useState(false)

  useEffect(() => {
    if (
      all(equals(true))([
        pathname.includes('public'),
        any(equals(true))([isVeteran, isResponder]),
        not(wasShownBanner)
      ])
    ) {
      setPageCount(inc(pageCount))
    }
  }, [pathname, wasShownBanner])

  // if user id, then we arent logged in
  const isLoggedIn = not(isNil(id))

  const boxStyles = {
    alignItems: 'center',
    // eslint-disable-next-line sort-keys
    display: { xs: 'none', lg: 'flex' },
    gridGap: 16
  }

  const LoggedOutHeader = () => (
    <>
      <Box sx={{ ...boxStyles }}>
        <LanguageSelect />
        <CrisisButton />
        <LogInButton isWhite>
          <FormattedMessage defaultMessage="Log In" id="r2Jjms" />
        </LogInButton>
        <Button
          LinkComponent={Link}
          onClick={() =>
            sendEvent('block_interactions', {
              block_title: 'Logged Out Header',
              click_element: 'Link',
              element_title: 'Sign Up',
              path: window.location.pathname
            })
          }
          to="/sign-up"
        >
          <FormattedMessage defaultMessage="Sign Up" id="39AHJm" />
        </Button>
      </Box>
      <CTABanner
        open={and(gte(pageCount, 2), not(wasShownBanner))}
        setWasShownBanner={setWasShownBanner}
      />
    </>
  )

  const LoggedInHeader = () => (
    <Box sx={{ ...boxStyles }}>
      <IconButton
        aria-label="Search for content"
        LinkComponent={Link}
        to="/search"
      >
        <SearchRoundedIcon sx={{ color: 'white' }} />
      </IconButton>
      <CrisisButton />
      <LanguageSelect />
      <ProfileDropdown />
    </Box>
  )

  if (equals(typeof window, 'undefined'))
    return (
      <AppBar
        component="header"
        position="sticky"
        sx={{
          bgcolor: 'school.primary',
          borderTop: '3px solid',
          borderTopColor: 'school.accent',
          height: 70
        }}
      />
    )

  if (pathname.includes('logout')) return null

  return (
    <AppBar
      component="header"
      position="sticky"
      sx={{
        bgcolor: 'school.primary',
        borderTop: '3px solid',
        borderTopColor: 'school.accent',
        height: 70
      }}
    >
      <Toolbar
        sx={{
          justifyContent: 'space-between',
          px: 1,
          py: 1
        }}
      >
        <Button
          aria-label="go to home page"
          LinkComponent={Link}
          sx={{
            background: `url(${logoImage})`,
            backgroundPosition: 'left center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            height: 36,
            // eslint-disable-next-line sort-keys
            maxWidth: { xs: 325, sm: 375 },
            p: 0,
            width: 1
          }}
          to={isLoggedIn ? '/home' : '/'}
          variant="text"
        />
        <Button
          href="#main"
          sx={{
            '&:focus': {
              left: 16
            },
            bgcolor: 'school.primary',
            fontFamily: 'mark-ot',
            left: '-100%',
            position: 'absolute',
            top: 72,
            transition: 'left 0.5s ease-out',
            zIndex: 5
          }}
        >
          <FormattedMessage defaultMessage="Skip to Main Content" id="6LeQ+9" />
        </Button>

        {isLoggedIn ? <LoggedInHeader /> : <LoggedOutHeader />}

        <IconButton
          aria-haspopup="menu"
          aria-label="Open Sidebar Navigation Menu"
          onClick={() => setIsOpen(not(isOpen))}
          // eslint-disable-next-line sort-keys
          sx={{ display: { xs: 'inline-flex', lg: 'none' } }}
        >
          <MenuRoundedIcon sx={{ color: 'white', fontSize: 26 }} />
        </IconButton>
      </Toolbar>
    </AppBar>
  )
}

Header.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired
}

export default Header
