const useLocalStorage = () => {
  const clear = () => localStorage.removeItem('@you:sessionId')

  const get = () => {
    if (typeof window !== 'undefined') {
      const key = localStorage.getItem('@you:sessionId')
      return JSON.parse(key)
    }
  }

  const save = v => {
    if (typeof window !== 'undefined') {
      localStorage.setItem('@you:sessionId', JSON.stringify(v))
    }
  }

  return {
    clear,
    get,
    save,
  }
}

export default useLocalStorage
