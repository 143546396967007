import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Button, Stack } from '@mui/material'
import { and, any, equals, isNil, not, or } from 'ramda'
import { useCurrentAppType } from 'hooks'
import PropTypes from 'prop-types'

import UserTypeSelect from './UserTypeSelectConnector'
import ResponderSelect from './ResponderSelectConnector'
import CountySelect from './CountySelectConnector'
import CreateAccount from './CreateAccountConnector'
import ActiveConsentForm from './ActiveConsentFormConnector'

const SocialSSOSignUpForm = ({
  consent,
  location,
  responderType,
  userType
}) => {
  const { isResponder, isVeteran } = useCurrentAppType()
  const [isConsented, setIsConsented] = useState(false)

  const isDisabled = () => {
    if (and(isResponder, or(isNil(responderType), not(consent)))) return true
    if (
      and(
        isVeteran,
        any(equals(true))([isNil(location), isNil(userType), not(consent)])
      )
    )
      return true
    return false
  }

  return (
    <Stack spacing={2}>
      {isConsented ? (
        <CreateAccount />
      ) : (
        <>
          {isResponder && <ResponderSelect />}
          {isVeteran && <CountySelect />}
          {isVeteran && <UserTypeSelect />}
          <ActiveConsentForm />
          <Button
            disabled={isDisabled()}
            fullWidth
            onClick={() => setIsConsented(true)}
          >
            <FormattedMessage defaultMessage="Continue" id="acrOoz" />
          </Button>
        </>
      )}
    </Stack>
  )
}

SocialSSOSignUpForm.propTypes = {
  consent: PropTypes.bool.isRequired,
  location: PropTypes.shape({}),
  responderType: PropTypes.string,
  userType: PropTypes.string
}

SocialSSOSignUpForm.defaultProps = {
  location: null,
  responderType: null,
  userType: null
}

export default SocialSSOSignUpForm
