import { keyframes } from '@emotion/react'
import { Box } from '@mui/material'
import { useCurrentAppType } from 'hooks'

const PriorityHeroImage = () => {
  const { isVeteran } = useCurrentAppType()

  const getImage = (appType, device) =>
    `/images/dashboard-priorities/${device}/${appType}.png`

  const desktopImageUrl = () => {
    if (isVeteran) return getImage('veteran', 'desktop')
    return getImage('responder', 'desktop')
  }
  const mobileImageUrl = () => {
    if (isVeteran) return getImage('veteran', 'mobile')
    return getImage('responder', 'mobile')
  }

  const imageScroll = keyframes`
    from, 0%, 40%, to {
      transform: translate3d(10px,0,0);
    }

    40%, 80% {
      transform: translate3d(30px,0, 0);
    }

    80%, 100% {
      transform: translate3d(50px,0,0);
    }
`

  return (
    <Box
      sx={{
        height: '30vh',
        overflow: 'hidden',
        position: 'relative',
        width: 1,
        zIndex: 3
      }}
    >
      <Box
        sx={{
          backgroundImage:
            'linear-gradient(270deg, #FBFBFB 0%, rgba(255, 255, 255, 0) 64.79%, rgba(251, 251, 251, 0) 64.79%)',
          height: '100%',
          left: 0,
          position: 'absolute',
          top: 0,
          transform: 'rotate(-180deg)',
          width: '65%',
          zIndex: 5
        }}
      />
      <Box
        sx={{
          // eslint-disable-next-line sort-keys
          animation: { xs: 'none', md: `${imageScroll} 6s linear` },
          animationFillMode: 'forwards',
          backgroundImage: {
            xs: `url(${mobileImageUrl()})`,
            // eslint-disable-next-line sort-keys
            md: `url(${desktopImageUrl()})`
          },
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          // eslint-disable-next-line sort-keys
          backgroundSize: { xs: 'contain', md: 'cover' },
          height: 250
        }}
      />
      <Box
        sx={{
          backgroundImage:
            'linear-gradient(270deg, #FBFBFB 0%, rgba(255, 255, 255, 0) 64.79%, rgba(251, 251, 251, 0) 64.79%)',
          height: '100%',
          position: 'absolute',
          right: 0,
          top: 0,
          width: '65%',
          zIndex: 5
        }}
      />
    </Box>
  )
}

export default PriorityHeroImage
