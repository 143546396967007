import PropTypes from 'prop-types'
import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardMedia
} from '@mui/material'
import { Link } from 'react-router-dom'
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded'
import useAnalytics from 'hooks/useAnalytics'

const WelcomeNavItem = ({ image, internalUrl, title }) => {
  const { sendEvent } = useAnalytics()

  const handleClick = () =>
    sendEvent('block_interactions', {
      block_title: 'OFD Nav Cards',
      click_element: 'Link',
      element_title: title,
      path: window.location.pathname
    })

  return (
    <Card
      sx={{
        bgcolor: 'transparent',
        boxShadow: 0,
        maxWidth: 250,
        minWidth: 50,
        width: 1
      }}
    >
      <CardActionArea component={Link} onClick={handleClick} to={internalUrl}>
        <CardMedia
          component="img"
          height={{ xs: 100, lg: 320 }}
          src={image}
          sx={{
            '&:hover': {
              height: { lg: 330 }
            }
          }}
        />
        <CardActions
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <Button
            sx={{
              color: 'black',
              display: 'flex',
              flexDirection: 'column',
              fontWeight: 700,
              mb: 1,
              mt: 1,
              px: 0,
              py: 1
            }}
            tabIndex={-1}
            variant="text"
          >
            {title}
            <ArrowForwardRoundedIcon />
          </Button>
        </CardActions>
      </CardActionArea>
    </Card>
  )
}

WelcomeNavItem.propTypes = {
  image: PropTypes.string.isRequired,
  internalUrl: PropTypes.string.isRequired,
  title: PropTypes.any.isRequired
}

export default WelcomeNavItem
