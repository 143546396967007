import { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { getSubdomainInfoFromId } from 'utils/subdomain-functions'
import { getSelfCheckQuestionFromInterests } from 'utils/self-check-functions'
import { and, isNil, not, prop, or } from 'ramda'
import { useGetSelfCheckQuizDataQuery } from 'api/statsApi'
import {
  Avatar,
  Box,
  Container,
  Skeleton,
  Stack,
  Typography
} from '@mui/material'
import { useCurrentAppType } from 'hooks'
import { useDispatch, useSelector } from 'react-redux'
import { setRealityCheckQuestion } from './onboardingSlice'
import PollCard from './PollCard'

const SelfCheck = () => {
  const { isVeteran } = useCurrentAppType()
  const dispach = useDispatch()
  const { userDomains } = useSelector(prop('user'))
  const { realityCheckQuestion, selectedInterests } = useSelector(
    prop('onboarding')
  )

  const [question, setQuestion] = useState(realityCheckQuestion)

  const { data, isFetching = true } = useGetSelfCheckQuizDataQuery()

  const subdomain = getSubdomainInfoFromId(
    prop('subdomain', question),
    userDomains
  )

  useEffect(() => {
    // only calculate new question if onboarding.realityCheckQuestion
    // gets re-initialized to {} by adding/removing interests
    if (and(isNil(prop('subdomain', realityCheckQuestion)), not(isNil(data)))) {
      const question = getSelfCheckQuestionFromInterests(
        selectedInterests,
        data
      )
      setQuestion(question)
      dispach(setRealityCheckQuestion(question))
    }
  }, [data])

  return (
    <Container maxWidth="sm" sx={{ pt: 2 }}>
      {subdomain && (
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="center"
          mb={6}
        >
          <Box mr={2}>
            <Avatar src={subdomain.display_image} variant="profile" />
          </Box>
          <Box>
            <Typography variant="body2">
              {isVeteran ? (
                <FormattedMessage
                  defaultMessage="Since you prioritized"
                  id="JxLyyR"
                />
              ) : (
                <FormattedMessage
                  defaultMessage="Since you’re interested in"
                  id="kp5KCE"
                />
              )}
            </Typography>
            <Typography variant="body2">
              <FormattedMessage
                defaultMessage="{title}..."
                id="t5Iszq"
                values={{ title: subdomain.onboarding_title }}
              />
            </Typography>
          </Box>
        </Stack>
      )}
      {or(isFetching, isNil(prop('id', question))) ? (
        <Skeleton
          data-testid="skeleton"
          height={400}
          sx={{ borderRadius: 2 }}
          variant="rectangular"
          width="100%"
        />
      ) : (
        <PollCard pollingQuestion={question} />
      )}
    </Container>
  )
}

export default SelfCheck
