/* eslint-disable sort-keys */
import { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { succeedThriveMatterFrontendUrlSlugs } from 'utils/domain-functions'
import { Box, Container, Typography } from '@mui/material'
import { prop, and, or, propEq, defaultTo, isNil, not, isEmpty } from 'ramda'
import { useCurrentAppType, useGetSchoolName } from '../../hooks'
import { useUpdateProfileMutation } from 'api/profileApi'
import { useDispatch, useSelector } from 'react-redux'
import { updateUserData } from 'store/userSlice'

import FilterSortMenu from 'components/common/FilterSortMenu'
import PageHeroBanner from 'components/common/PageHeroBanner'
import Resources from './components/Resources'
import CalloutBox from './components/CalloutBox'
import LocationSelector from './components/LocationSelector'

const ResourcesPage = () => {
  const { isVeteran, isResponder } = useCurrentAppType()
  const { banner, locations, show_locations_in_profile } = useSelector(
    prop('school')
  )
  const { location_id, org_resource_context_copy, userDomains } = useSelector(
    prop('user')
  )
  const dispatch = useDispatch()
  const [domainId, setDomainId] = useState(0)

  const [updateProfile] = useUpdateProfileMutation()

  useEffect(() => {
    dispatch(
      updateUserData({ name: 'has_viewed_campus_resources', value: true })
    )
    // postUserViewedACampusResource
    const body = new FormData()
    body.append('has_viewed_campus_resources', 1)
    updateProfile(body)
  }, [])

  const showLocationsFilter = and(
    show_locations_in_profile,
    or(isResponder, isVeteran)
  )
  const locationName = useGetSchoolName(location_id)

  const locationBannerImage = () => {
    const location = locations.find(propEq('id', location_id))
    const locationBannerImage = prop('banner', location)
    return defaultTo(banner, locationBannerImage)
  }

  if (isEmpty(userDomains)) return null

  const { succeed, thrive, matter } =
    succeedThriveMatterFrontendUrlSlugs(userDomains)

  const getFilterOptions = ({ id, frontend_name, subtitle }) => ({
    id,
    name: `${frontend_name} / ${subtitle}`
  })

  const filterOptions = [
    getFilterOptions(succeed),
    getFilterOptions(thrive),
    getFilterOptions(matter)
  ]

  return (
    <Box>
      <Container maxWidth="xl">
        {not(isNil(locationBannerImage())) && (
          <Box
            className="banner"
            sx={{
              pt: locationBannerImage ? null : '2%'
            }}
          >
            <PageHeroBanner imageUrl={locationBannerImage()} />
          </Box>
        )}
        <Box
          sx={{
            pb: 3,
            pl: 4,
            pr: 2
          }}
        >
          {showLocationsFilter &&
            (or(isVeteran, isResponder) ? (
              <Typography
                sx={{
                  my: { xs: 6, md: 2.5 },
                  textAlign: 'center'
                }}
                variant="h1"
              >
                <FormattedMessage
                  defaultMessage="Resources Near Me"
                  id="TMmOp9"
                />
              </Typography>
            ) : (
              <Typography
                sx={{
                  my: { xs: 6, md: 2.5 },
                  textAlign: 'center'
                }}
                variant="h1"
              >
                <FormattedMessage
                  defaultMessage="{locationName} Resources"
                  id="6cfbB5"
                  values={{ locationName }}
                />
              </Typography>
            ))}

          {showLocationsFilter && <LocationSelector />}

          {!showLocationsFilter && (
            <Typography
              sx={{
                my: { xs: 6, md: 2.5 },
                textAlign: 'center'
              }}
              variant="h1"
            >
              <FormattedMessage
                defaultMessage="{locationName} Resources"
                id="6cfbB5"
                values={{ locationName }}
              />
            </Typography>
          )}

          <CalloutBox topSectionCopy={org_resource_context_copy} />
        </Box>
      </Container>

      <FilterSortMenu
        activeId={domainId}
        handleChange={id => setDomainId(id)}
        options={filterOptions}
      />
      <Container maxWidth="xl">
        <Resources domainId={domainId} />
      </Container>
    </Box>
  )
}

export default ResourcesPage
