import SocialSignIn from './SocialSignIn'
import { prop } from 'ramda'
import { connect } from 'react-redux'
import { resetState } from 'pages/authentication/authenticationSlice'
import { setUserData } from 'store/userSlice'

const mapStateToProps = ({ authentication }) => ({
  email: prop('email', authentication),
  password: prop('password', authentication),
  ssoError: prop('ssoError', authentication),
  ssoErrorCode: prop('ssoErrorCode', authentication)
})

export default connect(mapStateToProps, {
  resetState,
  setUserData
})(SocialSignIn)
