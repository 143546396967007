import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const getSessionId = () => {
  if (typeof window !== 'undefined') {
    const key = localStorage.getItem('@you:sessionId')
    return JSON.parse(key)
  }
  return
}

const getLocale = () => {
  if (typeof window !== 'undefined') {
    return localStorage.getItem('@MT:locale')
  }
  return 'en'
}

const HOST = process.env.REACT_APP_PUBLIC_YOU_API_HOST

export const baseQuery = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${HOST}`,
    prepareHeaders: headers => {
      headers.set('Accept', 'application/json')
      headers.set('X-Session-Id', getSessionId())
      headers.set('locale', getLocale())
      return headers
    }
  }),
  endpoints: () => ({}),
  reducerPath: 'api',
  tagTypes: [
    'new cards',
    'card',
    'cards',
    'challenge card',
    'campus goals',
    'completed goals',
    'goal',
    'goals',
    'profile questions',
    'pick up cards',
    'user domains',
    'user',
    'reality score',
    'panic card',
    'school',
    'scores',
    'score',
    'self check',
    'about',
    'favorites',
    'student content',
    'geolocation resources',
    'featured content',
    'featured',
    'about',
    'be stats',
    'role types',
    'subdomain',
    'geo location',
    'public subdomain',
    'public user domains',
    'card sentiment',
    'legalDoc',
    'referral links'
  ]
})
