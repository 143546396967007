import { useState } from 'react'
import { Autocomplete, Box, TextField, Typography } from '@mui/material'

import {
  not,
  concat,
  prop,
  compose,
  assoc,
  tap,
  nthArg,
  defaultTo,
  isNil,
  slice,
  isEmpty,
  equals,
  evolve,
  always,
  modulo,
  length,
  inc
} from 'ramda'
import { FormattedMessage, useIntl } from 'react-intl'
import useCurrentDomain from 'hooks/useCurrentDomain'
import {
  useGetPublicCardsQuery,
  useGetPublicUserDomainsQuery
} from 'api/publicApi'
import { mapFiltersToQueryParams } from 'utils/helpers'
import { Container, Stack } from '@mui/system'
import { useCurrentAppType, useHandlePublicRoutes } from 'hooks'
import { useSelector } from 'react-redux'

import CTASection from '../components/CTASection'
import ResourceCard from '../components/cards/ResourceCard'
import CTACard from '../components/cards/CTACard'
import BreadCrumbs from '../components/BreadCrumbs'
import SortMenu from '../components/SortMenu'
import MasonryWrapper from 'components/common/MasonryWrapper'
import LoadMoreButton from 'components/common/LoadMoreButton'

const ResourcesPage = () => {
  useHandlePublicRoutes()
  const { banner, locations } = useSelector(prop('school'))
  const { currentDomain } = useCurrentDomain()
  const intl = useIntl()
  const { isVeteran } = useCurrentAppType()

  const [selected, setSelected] = useState(null)
  const [page, setPage] = useState(1)
  const [cardParams, setCardParams] = useState({
    campus_resource: 1,
    domains: 0,
    locations: null
  })

  const { data: cards = [], isFetching } = useGetPublicCardsQuery(
    concat(
      `org_domain=${currentDomain}&page[take]=${page * 11}&`,
      mapFiltersToQueryParams(cardParams)
    ),
    {
      refetchOnMountOrArgChange: true
    }
  )

  const { data: domains } = useGetPublicUserDomainsQuery(currentDomain)

  const getFilterOptions = ({ id, frontend_name, subtitle }) => ({
    label: `${frontend_name} / ${subtitle}`,
    value: id
  })

  const options = locations?.map(({ name, id }) => ({
    label: name,
    value: id
  }))

  const handleLocationChange = obj => {
    setCardParams(
      evolve(
        {
          campus_resource: always(1),
          locations: always(prop('value', obj))
        },
        cardParams
      )
    )
    setPage(1)
  }

  return (
    <Stack component="article" id="main" spacing={1}>
      <Box
        sx={{
          height: 370,
          // eslint-disable-next-line sort-keys
          mb: { xs: 4, sm: 8.5 },
          width: 1
        }}
      >
        <Box
          alt=""
          component="img"
          src={banner}
          sx={{
            border: 'none',
            height: '100%',
            objectFit: 'cover',
            width: '100%'
          }}
        />
      </Box>
      <Box>
        <Container maxWidth="xl" sx={{ mb: 2 }}>
          <Stack spacing={5}>
            <BreadCrumbs
              links={[
                {
                  href: '/',
                  label: intl.formatMessage({
                    defaultMessage: 'Explore',
                    id: '7JlauX'
                  })
                },
                {
                  href: '',
                  label: intl.formatMessage({
                    defaultMessage: 'Resources',
                    id: 'c/KktL'
                  })
                }
              ]}
            />
            <Typography alignSelf="center" color="primary" variant="h1">
              <FormattedMessage
                defaultMessage="Resources Near Me"
                id="TMmOp9"
              />
            </Typography>
            <Stack alignItems="center" spacing={1}>
              {isEmpty(selected) ? (
                <Typography alignSelf="center" variant="caption">
                  <FormattedMessage
                    defaultMessage="Select a county to see resources"
                    id="QUdSqj"
                  />
                </Typography>
              ) : (
                <Typography alignSelf="center" variant="caption">
                  <FormattedMessage
                    defaultMessage="Showing resources in:"
                    id="VTDUkr"
                  />
                </Typography>
              )}
              <Autocomplete
                getOptionLabel={prop('label')}
                isOptionEqualToValue={(option, value) =>
                  equals(prop('value', option), prop('value', value))
                }
                // Remember read RTL or in this case Bottom Up
                onChange={compose(
                  handleLocationChange,
                  tap(setSelected), // set location > { value, label }
                  nthArg(1) // second argument "value" :: obj :: { value, label }
                )}
                options={defaultTo([], options)}
                renderInput={params => (
                  <TextField
                    {...params}
                    label={isVeteran ? 'County' : 'State'}
                  />
                )}
                sx={{ width: 375 }}
                value={selected}
              />
            </Stack>
            <Stack spacing={1}>
              {not(isNil(domains)) && (
                <SortMenu
                  filter={prop('domains', cardParams)}
                  options={[
                    {
                      label: 'All',
                      value: 0
                    },
                    getFilterOptions(prop(0, domains)),
                    getFilterOptions(prop(1, domains)),
                    getFilterOptions(prop(2, domains))
                  ]}
                  setFilter={compose(setCardParams, assoc('domains'))}
                />
              )}
              <MasonryWrapper>
                {slice(0, 2, cards).map(card => (
                  <ResourceCard key={prop('id', card)} {...card} />
                ))}
                <CTACard />
                {slice(2, Infinity, cards).map(card => (
                  <ResourceCard key={prop('id', card)} {...card} />
                ))}
              </MasonryWrapper>
              {equals(modulo(length(cards), 11), 0) && (
                <LoadMoreButton
                  handleClick={() => setPage(inc(page))}
                  loading={isFetching}
                />
              )}
            </Stack>
          </Stack>
        </Container>
        <CTASection />
      </Box>
    </Stack>
  )
}

export default ResourcesPage
