import { Route, Routes } from 'react-router-dom'

import App from 'App'
// Non Authed Routes
import Welcome from 'pages/welcome/Welcome'
import About from 'pages/about-you/About'
import Accessibility from 'pages/accessibility/Accessibility'
import TermsAndConditions from 'pages/terms-and-conditions/TermsAndConditions'
import PrivacyPolicy from 'pages/privacy-policy/PrivacyPolicy'

// Auth
import SignIn from 'pages/authentication/sign-in/SignIn'
import SignInDemo from 'pages/authentication/sign-in/SignInDemo'
import SignUp from 'pages/authentication/sign-up/SignUp'
import SignUpDemo from 'pages/authentication/sign-up/SignUpDemo'
import Logout from 'pages/authentication/logout/Logout'
import EmailReset from 'pages/authentication/password-reset/EmailReset'
import PasswordReset from 'pages/authentication/password-reset/PasswordReset'

// SSO
import AccountCreated from 'pages/sso/AccountCreated'

// Public
import Public from 'pages/public/Public'
import SubdomainPublic from 'pages/public/subdomain/Subdomain'
import ArticlePublic from 'pages/public/article/Article'
import GroupPublic from 'pages/public/group/GroupPage'
import ResourcesPublic from 'pages/public/resources/ResourcesPage'
import ResourcesNearMePublic from 'pages/public/resources-near-me/ResourcesNearMe'
import StoriesPublic from 'pages/public/stories/Stories'

// Authenticated Routes
import Dashboard from 'pages/dashboard/Dashboard'
import SubdomainPage from 'pages/subdomain/SubdomainPage'
// User
import Verify from 'pages/user/verify/Verify'
import Unsubscribe from 'pages/user/unsubscribe/Unsubscribe'
import Profile from 'pages/user/profile/Profile'
// Articles
import ArticlePage from 'pages/article/Article'
import Challenge from 'pages/article/Challenge'
// Explore
import Explore from 'pages/explore/Explore'
import Group from 'pages/explore/Group'
// Favorites
import Favorites from 'pages/favorites/Favorites'
// Feedback
import FeedbackPage from 'pages/feedback/Feedback'
// Goals
import GoalsPage from 'pages/goals/Goals'
import CompletedGoal from 'pages/goals/complete/CompletedGoal'
import NewGoal from 'pages/goals/create/new/NewGoal'
import PremadeGoal from 'pages/goals/create/premade/PremadeGoal'
import EditGoal from 'pages/goals/edit/EditGoal'
import ViewGoal from 'pages/goals/view/ViewGoal'
// Onboarding
import Onboarding from 'pages/onboarding/Onboarding'
import OnboardingLayout from 'pages/onboarding/OnboardingLayout'
// Referral
import Referral from 'pages/referral/Referral'
// Resources
import Resources from 'pages/resources/Resources'
import ResourcesNearMe from 'pages/resources-near-me/ResourcesNearMe'
// Search
import Search from 'pages/search/Search'
// Self Checks
import SelfChecks from 'pages/self-checks/SelfChecks'
import QuizPage from 'pages/self-checks/quiz/QuizPage'
import ResultsPage from 'pages/self-checks/results/ResultsPage'
// Stats Dash
import StatsDash from 'pages/stats-dashboard/StatsDash'
// Stories
import Stories from 'pages/stories/Stories'
// Student Content
import StudentContent from 'pages/student-content/StudentContent'
// Tips
import Tips from 'pages/tips/Tips'

// Error
import NotFound from 'pages/404/404'

const AppRoutes = () => (
  <Routes>
    <Route element={<App />} />
    {/* Non Authenticated Routes */}
    <Route element={<Welcome />} index />
    <Route element={<About />} path="about-you" />
    <Route element={<Accessibility />} path="accessibility" />
    <Route element={<TermsAndConditions />} path="terms-and-conditions" />
    <Route element={<PrivacyPolicy />} path="privacy-policy" />

    {/* Authentication & SSO */}
    <Route path="sign-in">
      <Route element={<SignIn />} index />
      <Route element={<SignInDemo />} path="demo" />
    </Route>
    <Route path="sign-up">
      <Route element={<SignUp />} index />
      <Route element={<SignUpDemo />} path="demo" />
    </Route>
    <Route
      element={<AccountCreated />}
      path="sso/login/:token/:accountCreated"
    />
    <Route element={<Logout />} path="logout" />
    <Route path="password-reset">
      <Route element={<EmailReset />} index />
      <Route element={<PasswordReset />} path=":token" />
    </Route>

    {/* Public */}
    <Route path="public">
      {/* Removed public root page and changed breadcrumbs to redirect to '/' */}
      {/* <Route element={<Public />} index /> */}
      <Route element={<SubdomainPublic />} path=":domain/:subdomain" />
      <Route element={<ArticlePublic />} path="article/:id/:name" />
      <Route element={<GroupPublic />} path="group/:groupId" />
      <Route element={<ResourcesPublic />} path="resources" />
      <Route element={<ResourcesNearMePublic />} path="resources-near-me" />
      <Route element={<StoriesPublic />} path="stories" />
    </Route>

    {/* Authenticated Routes */}
    {/* User */}
    <Route element={<Verify />} path="verify/:token" />
    <Route element={<Unsubscribe />} path="unsubscribe/:type/:email" />
    <Route element={<Profile />} path="profile" />
    <Route element={<Dashboard />} path="home" />
    <Route element={<SubdomainPage />} path=":domainName/:subdomainName" />
    {/* Articles */}
    <Route path="article/:id/:articleName">
      <Route element={<ArticlePage />} index />
      <Route element={<Challenge />} path="challenge" />
    </Route>
    {/* Explore */}
    <Route path="explore">
      <Route element={<Explore />} index />
      <Route element={<Group />} path="group/:groupId" />
    </Route>
    {/* Favorites */}
    <Route element={<Favorites />} path="favorites" />
    {/* Feedback */}
    <Route element={<FeedbackPage />} path="feedback" />
    {/* Goals */}
    <Route path="goals">
      <Route element={<GoalsPage />} index />
      <Route element={<CompletedGoal />} path="complete" />
      <Route element={<NewGoal />} path="create/new" />
      <Route element={<PremadeGoal />} path="create/premade" />
      <Route element={<EditGoal />} path="edit/:id" />
      <Route element={<ViewGoal />} path="view/:id" />
    </Route>
    {/* Onboarding */}
    <Route path="onboarding">
      <Route element={<Onboarding />} index />
      <Route element={<OnboardingLayout />} path=":step" />
    </Route>
    {/* Referral */}
    <Route element={<Referral />} path="referral" />
    {/* Resources */}
    <Route element={<Resources />} path="resources" />
    <Route element={<ResourcesNearMe />} path="resources-near-me" />
    {/* Search */}
    <Route element={<Search />} path="search" />
    {/* Self Checks */}
    <Route path="self-checks">
      <Route element={<SelfChecks />} index />
      <Route element={<QuizPage />} path=":quiz" />
      <Route element={<ResultsPage />} path="results/:quiz" />
    </Route>
    {/* Stats */}
    <Route element={<StatsDash />} path="stats-dashboard" />
    {/* Stories */}
    <Route element={<Stories />} path="stories" />
    {/* Student Content */}
    <Route element={<StudentContent />} path="student-content" />
    {/* Tips */}
    <Route element={<Tips />} path="tips" />
    {/* Error */}
    <Route element={<NotFound />} path="*" />
  </Routes>
)

export default AppRoutes
