import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useAnalytics, useCurrentAppType } from 'hooks'
import {
  all,
  and,
  any,
  assoc,
  equals,
  gte,
  isNil,
  length,
  not,
  prop,
  reject
} from 'ramda'
import { Button, CircularProgress, Stack, Typography } from '@mui/material'
import { useSignUpMutation } from 'api/authApi'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { getBasicBodyCopy } from 'pages/authentication/authenticationSlice'

import ActiveConsentForm from './ActiveConsentFormConnector'
import ResponderSelect from './ResponderSelectConnector'
import CountySelect from './CountySelectConnector'
import UserTypeSelect from './UserTypeSelectConnector'
import EmailAndPassword from 'pages/authentication/components/EmailAndPasswordConnector'
import SuccessMessage from 'components/common/SuccessMessage'
import ErrorMessage from 'components/common/ErrorMessage'

const BasicSignUpForm = ({
  consent,
  email = null,
  emailError,
  password = null,
  passwordError,
  revalidation = null
}) => {
  const {
    domain,
    has_secondary_user_type,
    show_locations_at_signup,
    locations
  } = useSelector(prop('school'))
  const { isVeteran, isResponder } = useCurrentAppType()
  const { sendEvent } = useAnalytics()

  const [error, setError] = useState(false)

  const [signUp, { isLoading, data }] = useSignUpMutation()

  const body = useSelector(({ authentication }) =>
    getBasicBodyCopy(authentication)
  )

  const onSubmit = () => {
    if (emailError) {
      sendEvent('sign-up', {
        auth_type: 'traditional',
        // eslint-disable-next-line quotes
        validation_errors: ["Hmm, this email address isn't valid."],
        validation_success: false
      })
    }
    if (not(consent)) {
      setError(true)
      return
    }
    setError(false)
    signUp(reject(isNil, assoc('org_domain', domain, body)))
  }

  return (
    <Stack
      component="form"
      onSubmit={e => {
        e.preventDefault()
        onSubmit()
      }}
      spacing={2}
    >
      <SuccessMessage
        hasSuccess={prop('success', data)}
        message={
          <FormattedMessage
            defaultMessage="Success! Check your email to verify your new account."
            id="MGQUIN"
          />
        }
      />
      <ErrorMessage
        hasError={and(not(isNil(data)), not(prop('success', data)))}
        message={prop('message', data)}
      />
      {isResponder && <ResponderSelect />}

      {isVeteran && <CountySelect />}

      {all(equals(true))([
        show_locations_at_signup,
        not(isVeteran),
        not(isResponder),
        gte(length(locations), 2)
      ]) && <CountySelect />}

      {has_secondary_user_type && <UserTypeSelect />}

      <EmailAndPassword revalidate />

      <ActiveConsentForm />

      {error && (
        <Typography sx={{ color: 'error.main' }}>
          <FormattedMessage
            defaultMessage="Oops! You need to accept to continue."
            id="1ovZJl"
          />
        </Typography>
      )}

      <Button
        disabled={any(equals(true))([
          isNil(email),
          isNil(password),
          isNil(revalidation),
          not(equals(password, revalidation)),
          error,
          emailError,
          passwordError
        ])}
        fullWidth
        type="submit"
      >
        {isLoading ? (
          <CircularProgress size={24} sx={{ color: 'school.primary' }} />
        ) : (
          <FormattedMessage defaultMessage="Create Account" id="5JcXdV" />
        )}
      </Button>
    </Stack>
  )
}

BasicSignUpForm.propTypes = {
  consent: PropTypes.bool.isRequired,
  email: PropTypes.string,
  emailError: PropTypes.bool.isRequired,
  password: PropTypes.string,
  passwordError: PropTypes.bool.isRequired,
  revalidation: PropTypes.string
}

export default BasicSignUpForm
