import { FormattedMessage } from 'react-intl'
import { prop } from 'ramda'
import {
  Box,
  Card,
  CardContent,
  Divider,
  Typography,
  Stack
} from '@mui/material'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

import SocialSSOSignUpForm from './components/SocialSSOSignUpFormConnector'
import BasicSignUpForm from './components/BasicSignUpFormConnector'

const SignUp = () => {
  const { name, enable_social_sso } = useSelector(prop('school'))

  const renderComponent = () => {
    if (enable_social_sso) {
      return <SocialSSOSignUpForm />
    }
    return <BasicSignUpForm />
  }

  return (
    <Box
      sx={{
        maxWidth: 600,
        // eslint-disable-next-line sort-keys
        mt: { xs: 4, lg: 8 },
        mx: 'auto'
      }}
    >
      <Card
        sx={{
          borderTop: '4px solid',
          borderTopColor: 'school.accent'
        }}
      >
        <CardContent>
          <Typography component="h1" pb={1} variant="h4">
            <FormattedMessage
              defaultMessage="Create your private account"
              id="g+gKIh"
            />
          </Typography>
          <Typography variant="body2">
            <FormattedMessage
              defaultMessage="Get access to interactive tools & well-being resources available through {school}."
              id="o4jkyB"
              values={{
                school: name
              }}
            />
          </Typography>
          <Stack alignItems="center" direction="row" mt={2} spacing={0.5}>
            <Typography variant="body2">
              <FormattedMessage
                defaultMessage="Already have an account? "
                id="gWgSaX"
              />
            </Typography>
            <Typography
              color="primary"
              component={Link}
              sx={{
                fontSize: 16,
                textDecoration: 'underline'
              }}
              to="/sign-in"
            >
              <FormattedMessage defaultMessage="Log in." id="WZDtf5" />
            </Typography>
          </Stack>
          <Divider />
          {renderComponent()}
        </CardContent>
      </Card>
    </Box>
  )
}

export default SignUp
