import { Box, Stack } from '@mui/material'
import PropTypes from 'prop-types'

const VideoHero = ({ children, urlSlug }) => {
  return (
    <Stack
      sx={{
        alignItems: 'center',
        minHeight: { xs: 600, md: 678 },
        position: 'relative',
        width: 1
      }}
    >
      <Stack flex={1}>
        <Box
          sx={{
            background:
              'linear-gradient(0deg, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0.60) 100%)',
            bottom: 0,
            left: 0,
            position: 'absolute',
            right: 0,
            top: 0,
            zIndex: 1
          }}
        />
        <Box
          autoPlay
          component="video"
          data-testid="banner-video"
          disablePictureInPicture
          minHeight={{ xs: 450, md: 678 }}
          muted
          objectFit="cover"
          playsInline
          src={`https://grit-you-pro.s3.amazonaws.com/ovs-assets/${urlSlug}`}
          sx={{
            height: 1,
            // eslint-disable-next-line sort-keys
            left: 0,
            objectFit: 'cover',
            position: 'absolute',
            top: 0,
            width: 1,
            zIndex: 0
          }}
        />
        <Stack
          sx={{
            alignItems: 'center',
            bottom: { xs: 64, md: 36 },
            height: 1,
            justifyContent: 'center',
            left: 0,
            position: 'absolute',
            width: 1,
            zIndex: 1
          }}
        >
          {children}
        </Stack>
      </Stack>
    </Stack>
  )
}

VideoHero.propTypes = {
  children: PropTypes.node.isRequired,
  urlSlug: PropTypes.string.isRequired
}

export default VideoHero
