import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { store } from './store/store'

import HeadComponent from './Head'
import AnalyticsComponent from './Analytics'
import LanguageHandler from 'components/language/LanguageHandler'
import SchoolLoader from 'components/school/SchoolLoader'
import AuthorizationHandler from 'components/authorization/AuthorizationHandler'
import AppFlowHandler from 'components/app-flow/AppFlowHandler'
import HistoryHandler from 'components/history/HistoryHandler'
import SessionHandler from 'components/session/SessionHandler'
import MuiThemeProvider from 'theme/MuiThemeProvider'

import UserLoader from 'components/user/UserLoader'
import AlertWrapper from 'components/alert/AlertWrapper'
import EmailOptInModal from 'components/modals/EmailOptInModalConnector'
import CrisisModal from 'components/modals/CrisisModalConnector'
import MainWrapper from 'components/main-wrapper/MainWrapper'
import AppRoutes from 'routes/AppRoutes'

const App = () => (
  <Provider store={store}>
    <GoogleOAuthProvider
      clientId={process.env.REACT_APP_PUBLIC_SOCIAL_SSO_GOOGLE_CLIENTID}
    >
      <HelmetProvider>
        <HeadComponent />
        <AnalyticsComponent />
        <LanguageHandler>
          <SchoolLoader />
          <MuiThemeProvider>
            <BrowserRouter>
              <HistoryHandler />
              <SessionHandler />
              <AuthorizationHandler />
              <AppFlowHandler />
              <AlertWrapper />
              <CrisisModal />
              <EmailOptInModal />
              <UserLoader />
              <MainWrapper>
                <AppRoutes />
              </MainWrapper>
            </BrowserRouter>
          </MuiThemeProvider>
        </LanguageHandler>
      </HelmetProvider>
      <script async src="https://accounts.google.com/gsi/client" />
    </GoogleOAuthProvider>
  </Provider>
)

export default App
