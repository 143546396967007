import CreateAccount from './CreateAccount'
import { prop } from 'ramda'
import { connect } from 'react-redux'

const mapStateToProps = ({ authentication }) => ({
  email: prop('email', authentication),
  emailError: prop('emailError', authentication),
  password: prop('password', authentication),
  passwordError: prop('passwordError', authentication),
  revalidation: prop('revalidation', authentication)
})

export default connect(mapStateToProps)(CreateAccount)
