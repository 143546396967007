/* eslint-disable sort-keys */
import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Stack } from '@mui/material'
import { equals, prop, any, not, isNil, __, sort, or } from 'ramda'
import { useGetUserDomainsQuery } from 'api/userApi'
import { useDispatch, useSelector } from 'react-redux'
import { setUserDomains } from 'store/userSlice'

import Header from 'components/header/Header'
import Sidebar from 'components/sidebar/Sidebar'
import Footer from 'components/footer/Footer'
import OFDFooter from 'pages/public/components/OFDFooter'
import LoggedOutSidebar from 'components/sidebar/LoggedOutSidebar'
import { useLocation } from 'react-router-dom'

const MainWrapper = ({ children }) => {
  const { id, logoImage } = useSelector(prop('user'))
  const dispatch = useDispatch()
  const isLoggedIn = isNil(id)

  const { pathname } = useLocation()

  const anyCheck = [
    equals(pathname, '/'),
    pathname.includes('sign-in'),
    pathname.includes('sign-up'),
    pathname.includes('onboarding'),
    pathname.includes('accessibility'),
    pathname.includes('privacy'),
    pathname.includes('terms'),
    pathname.includes('feedback'),
    pathname.includes('public'),
    pathname.includes('verify'),
    pathname.includes('password-reset'),
    pathname.includes('referral'),
    pathname.includes('redirect'),
    pathname.includes('about-you'),
    pathname.includes('logout'),
    pathname.includes('profile'),
    pathname.includes('tips'),
    equals(pathname, '/resources'),
    pathname.includes('subdomainName'),
    pathname.includes('about'),
    pathname.includes('article'),
    pathname.includes('succeed'),
    pathname.includes('matter'),
    pathname.includes('thrive')
  ]

  const [isOpen, setIsOpen] = useState(false)
  const [isOpenSidebar, setIsOpenSidebar] = useState(false)

  const { data: userDomains } = useGetUserDomainsQuery(__, {
    skip: isNil(id)
  })

  useEffect(() => {
    if (not(isNil(userDomains))) {
      // writing this here to make sure user domains are set in the particular order
      // if the decision to change order is different then just remove this check
      const orderDomains = domains => {
        const order = ['Succeed', 'Thrive', 'Matter']
        return sort(
          (a, b) =>
            order.indexOf(prop('title', a)) - order.indexOf(prop('title', b)),
          domains
        )
      }
      dispatch(setUserDomains(orderDomains(userDomains)))
    }
  }, [userDomains])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  const getFooter = () => {
    if (or(pathname.includes('public'), equals(pathname, '/'))) {
      return <OFDFooter />
    }
    return <Footer />
  }

  if (equals(typeof window, 'undefined')) return null

  return (
    <>
      <Header
        isOpen={not(isLoggedIn) ? isOpen : isOpenSidebar}
        logo={logoImage}
        setIsOpen={not(isLoggedIn) ? setIsOpen : setIsOpenSidebar}
      />
      <Stack direction="row">
        <Sidebar isOpen={isOpen} setIsOpen={setIsOpen} />
        <LoggedOutSidebar isOpen={isOpenSidebar} setIsOpen={setIsOpenSidebar} />
        <Box
          component="article"
          id="main"
          sx={{
            backgroundColor: '#FBFBFB',
            position: 'relative',
            pt: any(equals(true))(anyCheck) ? 0 : { xs: 2, md: 3, lg: 6 },
            width: 1
          }}
        >
          {children}
          {getFooter()}
        </Box>
      </Stack>
    </>
  )
}

MainWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.number,
  isLoggedIn: PropTypes.bool,
  logoImage: PropTypes.string,
  setUserDomains: PropTypes.func
}

export default MainWrapper
