import PropTypes from 'prop-types'
import { Card, CardContent, Stack, Typography } from '@mui/material'

const EmptyCard = ({ title, body }) => (
  <Card>
    <CardContent>
      <Stack spacing={1.5}>
        <Typography variant="cardTitle">{title}</Typography>
        {body}
      </Stack>
    </CardContent>
  </Card>
)

EmptyCard.propTypes = {
  body: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
}

export default EmptyCard
