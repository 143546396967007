/* eslint-disable sort-keys */
import { FormattedMessage } from 'react-intl'
import WelcomeHeroImage from './WelcomeHeroImage'
import { useAnalytics, useCurrentAppType } from 'hooks'
import { Box, Button, Stack, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded'

const WelcomeSectionAbout = () => {
  const { isVeteran } = useCurrentAppType()
  const { sendEvent } = useAnalytics()

  const imageUrl = () => {
    if (isVeteran) return '/images/welcome/about-images/ovs-about.jpg'
    return '/images/welcome/about-images/responder-about.jpg'
  }

  const headline = () => {
    if (isVeteran)
      return (
        <FormattedMessage
          defaultMessage="Veterans helping Veterans."
          id="9jKTua"
        />
      )
    return (
      <FormattedMessage
        defaultMessage="Responders use tools to save others. Here’s one built for them."
        id="vf23vB"
      />
    )
  }

  const ctaLink = () => {
    if (isVeteran)
      return (
        <FormattedMessage
          defaultMessage="Get involved with a free, confidential account"
          id="liIuxd"
        />
      )
    return (
      <FormattedMessage defaultMessage="Recover and Recharge" id="8dRohj" />
    )
  }

  const content = () => {
    if (isVeteran)
      return (
        <Typography variant="bodyLarge">
          <FormattedMessage
            defaultMessage="No one understands what it means to be a Veteran better than a fellow
      Veteran. With your free, confidential account you can find and connect
      with organizations, groups, resources and guides in your community and
      beyond. Get involved and discover how you can help your fellow
      Veterans in your back yard or across your state. Create a free,
      confidential account to see the full list."
            id="2MvHN+"
          />
        </Typography>
      )
    return (
      <FormattedMessage
        defaultMessage="In collaboration with the All Clear Foundation, GMR, and Anschutz Foundation, this platform was built to support all aspects of a responder’s life from finances and relationships, to physical, mental, and emotional well-being. Our only goal is to freely and confidentially connect responders to their own form of effective support."
        id="Pry6jC"
      />
    )
  }

  const HashCircle = () => (
    <Box
      sx={{
        left: { xs: 0, lg: '-3%' },
        position: 'absolute',
        top: { xs: 0, lg: '8%' },
        transform: 'rotate(180deg)',
        width: { xs: '95%', md: '85%', lg: '50%' }
      }}
    >
      <Box
        sx={{
          backgroundImage: 'url(/images/welcome/hash-circle.svg)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          pt: '100%',
          width: 1
        }}
      />
    </Box>
  )

  return (
    <Stack
      direction={{ xs: 'column', md: 'row' }}
      sx={{
        pb: { xs: '6em', lg: '10%', xl: 0 },
        position: 'relative',
        pt: { xs: '5%', md: 0, lg: '10%' }
      }}
    >
      <HashCircle />

      <Box
        sx={{
          flex: { xs: '0 0 100%', lg: '0 0 40%' },
          order: 1,
          p: { xs: 0, lg: '2% 0 15%' },
          position: 'relative'
        }}
      >
        <Box
          position="relative"
          sx={{
            width: 1
          }}
        >
          <WelcomeHeroImage imageUrl={imageUrl()} position="left" />
        </Box>
      </Box>

      <Box
        sx={{
          flex: { xs: '0 0 100%', lg: '0 0 55%' },
          order: isVeteran ? { xs: 2, lg: 1 } : 2,
          position: 'relative'
        }}
      >
        <Box
          sx={{
            p: { xs: '10% 3% 5%', lg: '0 10% 5%' },
            position: { xs: 'relative', lg: 'absolute' },
            top: { lg: '50%' },
            transform: { lg: 'translateY(-50%)' },
            width: 1
          }}
        >
          <Typography color="primary" variant="h2">
            {headline()}
          </Typography>
          <Typography component="p" variant="bodyLarge">
            {content()}
          </Typography>
          <Button
            endIcon={<ArrowForwardRoundedIcon />}
            LinkComponent={Link}
            onClick={() =>
              sendEvent('welcome_page', {
                element_title: 'Learn more about operation veteran strong',
                section: 'about block'
              })
            }
            sx={{
              color: 'primary.main',
              fontWeight: 700,
              px: 0
            }}
            to="/sign-up"
            variant="text"
          >
            {ctaLink()}
          </Button>
        </Box>
      </Box>
    </Stack>
  )
}

export default WelcomeSectionAbout
