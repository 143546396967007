import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import {
  TYPE_ASSESSMENT,
  TYPE_RESOURCE,
  TYPE_FACT_TIP,
  TYPE_LIFE_LESSON,
  TYPE_TOOL,
  TYPE_EVENT,
  TYPE_STORY,
  TYPE_CAMPUS_GOAL,
  TYPE_NOD_CHALLENGE,
  TYPE_GEO_RESOURCE,
  TYPE_TIP,
  TYPE_SKILL,
  TYPE_POLL,
  getVariablesForCardPreparation
} from 'utils/card-functions'

import PollOfMonth from '../poll-of-month/PollOfMonth'
import FactCard from './FactCard'
import SmallResourceCard from './SmallResourceCard'
import TipsCard from './TipsCard'
import NodCard from '../nod/NodCard'
import MiniSkillsCard from './MiniSkillsCard'
import MiniResourceCard from './MiniResourceCard'
import LargeSkillsCard from './LargeSkillsCard'
import MiniFactTipCard from './MiniFactTipCard'
import ExploreGoalCard from 'pages/goals/components/ExploreGoalCard'

import { prop, path, equals, and, length, gt, any, not, isNil } from 'ramda'
import useAnalytics from 'hooks/useAnalytics'
import { useLocation } from 'react-router-dom'

const PreparedCard = ({
  blockTitle = null,
  card = {},
  categoryTitle = null,
  flagEventImage = null,
  flagImage = null,
  flagStoryImage = null,
  highlightColor = null,
  isPublic = false,
  isStudentContent = false,
  isWelcome = false,
  secondaryColor = null,
  size = null
}) => {
  const { formatMessage } = useIntl()
  const { sendEvent } = useAnalytics()
  const { pathname } = useLocation()

  const { photo, internalUrl, articleDescription } =
    getVariablesForCardPreparation(card, isStudentContent, isPublic)

  const badge = () => {
    if (and(equals(cardType, TYPE_EVENT), not(isNil(flagEventImage))))
      return flagEventImage
    if (and(equals(cardType, TYPE_STORY), not(isNil(flagStoryImage))))
      return flagStoryImage
    return flagImage
  }

  const cardType = prop('type', card)
  const title = prop('title', card)
  const campusResource = prop('campus_resource', card)

  const sendKeenIOEvent = () => {
    if (isWelcome) {
      sendEvent('block_interactions', {
        block_title: 'OFD New & Trending',
        element_title: title,
        path: pathname
      })
    } else {
      sendEvent('block_interactions', {
        block_title: blockTitle,
        click_element: 'Link',
        element_title: title,
        path: pathname
      })
    }
  }

  if (equals(cardType, TYPE_POLL))
    return <PollOfMonth pollingQuestion={prop('poll_question', card)} />
  if (and(equals(cardType, TYPE_FACT_TIP), equals(size, 'mini')))
    return (
      <MiniFactTipCard
        handleAnalytics={sendKeenIOEvent}
        headline={title}
        resourceUrl={isWelcome ? `/public${internalUrl}` : internalUrl}
        subtitle={formatMessage({
          defaultMessage: 'expert advice',
          id: 'ohMJe+'
        })}
      />
    )
  if (equals(cardType, TYPE_FACT_TIP))
    return (
      <FactCard
        content={articleDescription}
        handleClick={sendKeenIOEvent}
        id={prop('id', card)}
        internalUrl={
          isWelcome
            ? `/public/article/${prop('id', card)}/${prop('title', card)}`
            : `/article/${prop('id', card)}/${prop('title', card)}`
        }
        tipTitle={title}
      />
    )
  if (equals(cardType, TYPE_NOD_CHALLENGE))
    return (
      <NodCard
        challenge={prop('challenge', card)}
        challengeTitle={title}
        description={prop('video_description', card)}
        imageUrl={path(['challenge', 'image_url'], card)}
        internalUrl={isWelcome ? `/public${internalUrl}` : internalUrl}
        studentCount={path(['challenge', 'participants'], card)}
      />
    )
  if (equals(cardType, TYPE_CAMPUS_GOAL))
    return (
      <ExploreGoalCard
        goalTitle={title}
        id={path(['campus_goal', 'card_id'], card)}
        stepDetail={
          gt(length(path(['campus_goal', 'steps'], card)), 0) &&
          path(['campus_goal', 'steps'], card)
        }
        totalSteps={length(path(['campus_goal', 'steps'], card))}
      />
    )
  if (and(equals(cardType, TYPE_SKILL), equals(size, 'mini')))
    return (
      <MiniSkillsCard
        handleAnalytics={sendKeenIOEvent}
        headline={title}
        imageUrl={photo}
        resourceUrl={isWelcome ? `/public${internalUrl}` : internalUrl}
      />
    )
  if (equals(cardType, TYPE_SKILL))
    return (
      <LargeSkillsCard
        handleAnalytics={sendKeenIOEvent}
        headline={title}
        imageUrl={photo}
        resourceUrl={isWelcome ? `/public${internalUrl}` : internalUrl}
        subtitle="Learn How To..."
      />
    )
  if (and(equals(cardType, TYPE_TIP), equals(size, 'mini')))
    return (
      <MiniResourceCard
        handleAnalytics={sendKeenIOEvent}
        headline={title}
        imageUrl={photo}
        resourceUrl={isWelcome ? `/public${internalUrl}` : internalUrl}
        subtitle={
          campusResource
            ? formatMessage({
                defaultMessage: 'local resource',
                id: 'yCwQMR'
              })
            : formatMessage({
                defaultMessage: 'expert advice',
                id: 'ohMJe+'
              })
        }
      />
    )
  if (equals(cardType, TYPE_TIP))
    return (
      <TipsCard
        categoryTitle={categoryTitle}
        handleAnalytics={sendKeenIOEvent}
        highlightColor={highlightColor}
        photo={photo}
        tipsTitle={title}
        tipsUrl={isWelcome ? `/public${internalUrl}` : internalUrl}
      />
    )

  if (
    and(
      any(equals(cardType))([
        TYPE_ASSESSMENT,
        TYPE_RESOURCE,
        TYPE_LIFE_LESSON,
        TYPE_TOOL,
        TYPE_EVENT,
        TYPE_GEO_RESOURCE,
        TYPE_STORY
      ]),
      equals(size, 'mini')
    )
  )
    return (
      <MiniResourceCard
        flagIcon={campusResource ? flagImage : null}
        handleAnalytics={sendKeenIOEvent}
        headline={title}
        imageUrl={photo}
        resourceUrl={isWelcome ? `/public${internalUrl}` : internalUrl}
        subtitle={
          campusResource
            ? formatMessage({
                defaultMessage: 'local resource',
                id: 'yCwQMR'
              })
            : formatMessage({
                defaultMessage: 'expert advice',
                id: 'ohMJe+'
              })
        }
      />
    )
  if (
    any(equals(cardType))([
      TYPE_ASSESSMENT,
      TYPE_RESOURCE,
      TYPE_LIFE_LESSON,
      TYPE_TOOL,
      TYPE_EVENT,
      TYPE_GEO_RESOURCE,
      TYPE_STORY
    ])
  )
    return (
      <SmallResourceCard
        badge={campusResource ? badge() : null}
        brandColor={secondaryColor}
        card={card}
        flagIcon={campusResource ? flagImage : null}
        handleAnalytics={sendKeenIOEvent}
        headline={title}
        imageUrl={photo}
        internalUrl={isWelcome ? `/public${internalUrl}` : internalUrl}
        isWelcome={isWelcome}
        subtitle={articleDescription}
      />
    )
}

PreparedCard.propTypes = {
  blockTitle: PropTypes.string,
  card: PropTypes.shape({}),
  categoryTitle: PropTypes.string,
  flagEventImage: PropTypes.string,
  flagImage: PropTypes.string,
  flagStoryImage: PropTypes.string,
  highlightColor: PropTypes.string,
  isPublic: PropTypes.bool,
  isStudentContent: PropTypes.bool,
  isWelcome: PropTypes.bool,
  secondaryColor: PropTypes.string,
  size: PropTypes.string
}

export default PreparedCard
