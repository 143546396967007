/* eslint-disable sort-keys */
import { FormattedMessage } from 'react-intl'
import { isNil, prop, equals } from 'ramda'
import { Box, Divider, Typography } from '@mui/material'
import LockRoundedIcon from '@mui/icons-material/LockRounded'
import { useGetOriginalProfileQuestionsQuery } from 'api/profileApi'
import { useSelector } from 'react-redux'

import ProfileQuestions from './ProfileQuestions'
import WhyWeAskForInfoDisclaimer from './WhyWeAskForInfoDisclaimer'
import ProfileAvatar from './ProfileAvatar'
import SubdomainSelector from 'components/common/SubdomainSelector'
import LocationSelect from './LocationSelect'

const QuestionsPage = () => {
  const { show_locations_in_profile } = useSelector(prop('school'))
  const { id, profile_answers, public_user } = useSelector(prop('user'))

  const { data } = useGetOriginalProfileQuestionsQuery()

  const _getHardwiredProfileQuestions = questionNamesArray =>
    prop('hardwired_profile_questions', data)
      ?.filter(({ name }) => questionNamesArray.includes(name))
      .map(q => ({
        control_type: equals(q.name, 'birthdate') ? 'date' : 'text',
        ...q
      }))

  const _lookupAnswerForHardwiredQuestion = name => {
    if (isNil(public_user)) return null
    if (equals('preferred_name', name))
      return prop('preferred_name', public_user)
    return prop('birthdate', public_user)
  }

  const _getHardwiredProfileAnswers = questionNamesArray => {
    // Mimic the userAnswers data format with data pulled from public_user
    return questionNamesArray.map(qName => {
      const question = prop('hardwired_profile_questions', data)?.find(
        q => q.name === qName
      )
      return {
        answers: [_lookupAnswerForHardwiredQuestion(qName)],
        profile_question_id: prop('id', question)
      }
    })
  }

  return (
    <Box sx={{ position: 'relative' }}>
      <Box
        sx={{
          display: 'grid',
          gridGap: '24px',
          pb: 4,
          px: { xs: 2, md: 3 }
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            gridGap: '16px',
            justifyContent: 'space-between'
          }}
        >
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              gridGap: '16px',
              mb: 4,
              mt: 1,
              justifyContent: 'space-between'
            }}
          >
            <Box
              sx={{
                bgcolor: 'white',
                borderRadius: 1,
                boxShadow: '0px 2px 4px 0px #4F4F4F40',
                display: 'flex',
                gridGap: 8,
                px: 2,
                py: 1.5
              }}
            >
              <LockRoundedIcon />
              <Typography color="secondary.dark" variant="body2">
                <FormattedMessage
                  defaultMessage="Your profile is 100% confidential."
                  id="AY4AcY"
                />
              </Typography>
            </Box>
          </Box>
        </Box>
        <ProfileAvatar />
        <Typography variant="subtitle4">User ID: {id}</Typography>
        <ProfileQuestions
          questions={_getHardwiredProfileQuestions(['preferred_name'])}
          userAnswers={_getHardwiredProfileAnswers(['preferred_name'])}
        />
        {show_locations_in_profile && <LocationSelect />}
        <ProfileQuestions
          questions={prop('profile_questions', data)?.filter(
            q =>
              q.control_type !== 'hardwired' &&
              q.control_type === 'dropdown-menu'
          )}
          userAnswers={profile_answers}
        />
        <ProfileQuestions
          questions={prop('profile_questions', data)?.filter(
            q =>
              q.control_type !== 'hardwired' &&
              q.control_type === 'select-single'
          )}
          userAnswers={profile_answers}
        />
        <WhyWeAskForInfoDisclaimer />
        <ProfileQuestions
          questions={prop('profile_questions', data)?.filter(
            q =>
              q.control_type !== 'hardwired' &&
              (q.control_type === 'select-single' ||
                q.control_type === 'radio-single')
          )}
          userAnswers={profile_answers}
        />
        <ProfileQuestions
          questions={prop('profile_questions', data)?.filter(
            q =>
              q.control_type !== 'hardwired' &&
              (q.control_type === 'select-multi' ||
                q.control_type === 'radio-multi')
          )}
          userAnswers={profile_answers}
        />
        <WhyWeAskForInfoDisclaimer />
        <Divider />
        <Box
          sx={{
            display: 'grid',
            gridGap: '24px'
          }}
        >
          <Typography variant="subtitle4">
            <FormattedMessage
              defaultMessage="Want a more personalized experience?"
              id="tZPD8F"
            />
          </Typography>
          <Typography variant="body2">
            <FormattedMessage
              defaultMessage="Select any identities that are relevant to you."
              id="lw317K"
            />
          </Typography>
          <Box display="flex" flexWrap="wrap">
            <ProfileQuestions
              questions={prop('profile_questions', data)?.filter(
                q =>
                  q.control_type !== 'hardwired' &&
                  q.control_type === 'yes-no-button'
              )}
              userAnswers={profile_answers}
            />
          </Box>
        </Box>
        <WhyWeAskForInfoDisclaimer />
        <Divider />
        <SubdomainSelector />
      </Box>
    </Box>
  )
}

export default QuestionsPage
