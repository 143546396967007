import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { useState } from 'react'
import { compose, path } from 'ramda'
import { useLocation } from 'react-router-dom'

import SuccessMessage from 'components/common/SuccessMessage'

const SalesforceForm = () => {
  const { pathname } = useLocation()

  const [value, setValue] = useState('')

  if (pathname.includes('success'))
    return (
      <Stack
        sx={{
          alignItems: 'center',
          bgcolor: 'success.light',
          border: '1px solid',
          borderColor: 'success.main',
          borderRadius: 1,
          mb: 4,
          // eslint-disable-next-line sort-keys
          p: { xs: 2, md: 4 },
          width: '100%'
        }}
      >
        <SuccessMessage
          hasSuccess
          message={
            <FormattedMessage
              defaultMessage="Thank you we'll be in touch soon!"
              id="kqasnB"
            />
          }
        />
      </Stack>
    )

  return (
    // <form
    //   action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
    //   method="POST"
    // >
    //   <input
    //     name="captcha_settings"
    //     type="hidden"
    //     value='{"keyname":"Responder","fallback":"true","orgId":"00Df2000001KnCT","ts":""}'
    //   />
    //   <input name="oid" type="hidden" value="00Df2000001KnCT" />
    //   <input name="retURL" type="hidden" value={`${window.location}#success`} />
    //   <label htmlFor="first_name">First Name</label>
    //   <input
    //     id="first_name"
    //     maxLength="40"
    //     name="first_name"
    //     size="20"
    //     type="text"
    //   />
    //   <br />
    //   <label htmlFor="last_name">Last Name</label>
    //   <input
    //     id="last_name"
    //     maxLength="80"
    //     name="last_name"
    //     size="20"
    //     type="text"
    //   />
    //   <br />
    //   <label htmlFor="company">Company</label>
    //   <input id="company" maxLength="40" name="company" size="20" type="text" />
    //   <br />
    //   <label htmlFor="email">Email</label>
    //   <input id="email" maxLength="80" name="email" size="20" type="text" />
    //   <br></br>
    //   Why are you reaching out?:
    //   <select
    //     id="00N5x00000EkcgB"
    //     name="00N5x00000EkcgB"
    //     title="Why are you reaching out?"
    //   >
    //     <option value="">--None--</option>
    //     <option value="Help others find this tool or bring it to my state">
    //       Help others find this tool or bring it to my state
    //     </option>
    //     <option value="Partnership opportunity">Partnership opportunity</option>
    //     <option value="Media inquiry">Media inquiry</option>
    //     <option value="Recommend a great resource to include">
    //       Recommend a great resource to include
    //     </option>
    //     <option value="Other">Other</option>
    //   </select>
    //   <br />
    //   <div
    //     className="g-recaptcha"
    //     data-sitekey="6LeJBPwlAAAAAHuAKbKjn2g3M62h4vVH7vQnjdyT"
    //   ></div>
    //   <br />
    //   <input name="submit" type="submit" />
    // </form>

    <Box
      sx={{
        bgcolor: 'primary.light',
        border: '1px solid',
        borderColor: 'primary.dark',
        borderRadius: 1,
        mb: 4,
        // eslint-disable-next-line sort-keys
        p: { xs: 2, md: 4 },
        width: '100%'
      }}
    >
      <Typography sx={{ fontSize: '22px', mb: 2 }} variant="h4">
        Want to bring this tool to your state?
      </Typography>

      <Typography sx={{ mb: 2, mt: 0, mx: 'auto' }} variant="subtitle1">
        Fill out the form to start the conversation.
      </Typography>

      <Box
        action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
        component="form"
        method="POST"
      >
        <Box sx={{ display: 'none' }}>
          <TextField name="oid" type="hidden" value="00Df2000001KnCT" />
          <TextField
            id="recordType"
            name="recordType"
            type="hidden"
            value="0125x000000ccrmAAA"
          />
          <TextField
            name="retURL"
            type="hidden"
            // value={`${window.location}/about-you#success`}
            value="https://you.responderstrong.org/about-you#success"
          />
          {/* Debugging */}
          {/* <TextField name="debug" type="hidden" value={1} />
          <TextField
            name="debugEmail"
            type="hidden"
            value="colin@gritdigitalhealth.com"
          /> */}
        </Box>
        <Box
          sx={{
            display: 'grid',
            gridGap: 16,
            // eslint-disable-next-line sort-keys
            gridTemplateColumns: { xs: '1fr', lg: 'repeat(2, 1fr)' },
            mb: 2
          }}
        >
          <TextField
            id="first_name"
            label="First Name"
            maxLength="40"
            name="first_name"
            required
          />
          <TextField
            id="last_name"
            label="Last Name"
            maxLength="80"
            name="last_name"
            required
          />
          <TextField
            id="company"
            label="Company"
            maxLength="40"
            name="company"
            required
          />
          <TextField
            id="email"
            label="Email"
            maxLength="80"
            name="email"
            required
            type="email"
          />
          <FormControl>
            <InputLabel>
              <FormattedMessage
                defaultMessage="Why are you reaching out?"
                id="7X3M4o"
              />
            </InputLabel>
            <Select
              data-testid="select"
              id="00N5x00000EkcgB"
              name="00N5x00000EkcgB"
              onChange={compose(setValue, path(['target', 'value']))}
              value={value}
            >
              <MenuItem value="">None</MenuItem>
              <MenuItem value="Help others find this tool or bring it to my state">
                Help others find this tool or bring it to my state
              </MenuItem>
              <MenuItem value="Partnership opportunity">
                Partnership opportunity
              </MenuItem>
              <MenuItem value="Media inquiry">Media inquiry</MenuItem>
              <MenuItem value="Recommend a great resource to include">
                Recommend a great resource to include
              </MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Button sx={{ display: 'flex', m: '0 auto' }} type="submit">
          <FormattedMessage defaultMessage="Submit Form" id="7tFAqp" />
        </Button>
      </Box>
    </Box>
  )
}

export default SalesforceForm
