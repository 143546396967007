import { FormattedMessage } from 'react-intl'
import { useGetValidRoleTypesQuery } from 'api/userApi'
import { FormControl, InputLabel, MenuItem, Select, Stack } from '@mui/material'
import { compose, defaultTo, isNil, path } from 'ramda'
import PropTypes from 'prop-types'
import CountySelect from './CountySelectConnector'

const ResponderSelect = ({ responderType, setResponderType }) => {
  const { data } = useGetValidRoleTypesQuery()

  if (isNil(data)) return null

  const responderRoleTypes = data.map(role => ({ label: role, value: role }))

  return (
    <Stack spacing={2}>
      <FormControl fullWidth required>
        <InputLabel id="responder-type-select">
          <FormattedMessage
            defaultMessage="What type of responder are you?"
            id="HQ7K4R"
          />
        </InputLabel>
        <Select
          aria-label="responder-type-select"
          id="responder-type-select"
          label={
            <FormattedMessage
              defaultMessage="What type of responder are you?"
              id="HQ7K4R"
            />
          }
          labelId="userType"
          onChange={compose(setResponderType, path(['target', 'value']))}
          value={defaultTo('', responderType)}
        >
          {responderRoleTypes.map(({ label, value }) => (
            <MenuItem key={label} value={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <CountySelect />
    </Stack>
  )
}

ResponderSelect.propTypes = {
  responderType: PropTypes.string,
  setResponderType: PropTypes.func.isRequired,
}

ResponderSelect.defaultProps = {
  responderType: null,
}

export default ResponderSelect
