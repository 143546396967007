import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { TYPE_STORY } from 'utils/card-functions'
import { equals, prop, length } from 'ramda'
import { Box, Stack, Typography } from '@mui/material'

import PreparedCard from './PreparedCardConnector'

const RecommendedCards = ({ recommendedCards, type = null, blockTitle }) => (
  <Stack
    alignItems="center"
    spacing={3}
    sx={{
      pb: 3,
      // eslint-disable-next-line sort-keys
      px: { xs: 2, md: 3 }
    }}
  >
    <Typography component="span" variant="cardTitle">
      {equals(type, TYPE_STORY) ? (
        <FormattedMessage defaultMessage="Supporting Resources" id="3kJcZk" />
      ) : (
        <FormattedMessage defaultMessage="Recommended for You" id="41sr4c" />
      )}
    </Typography>
    <Box
      sx={{
        display: 'grid',
        gridGap: 16,
        gridTemplateColumns: {
          xs: '1fr',
          // eslint-disable-next-line sort-keys
          md: `repeat(${length(recommendedCards)}, 1fr)`
        }
      }}
    >
      {recommendedCards.map(card => (
        <PreparedCard
          blockTitle={blockTitle}
          card={card}
          key={prop('id', card)}
        />
      ))}
    </Box>
  </Stack>
)

RecommendedCards.propTypes = {
  blockTitle: PropTypes.string.isRequired,
  recommendedCards: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  type: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default RecommendedCards
