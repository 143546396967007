import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grow,
  Typography
} from '@mui/material'

import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import { excerpt, metersToMiles, sanitizeUrl } from 'utils/helpers'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { isNil, defaultTo, not, or, isEmpty, prop } from 'ramda'
import { useAnalytics, useCurrentAppType } from 'hooks'
import { useSelector } from 'react-redux'

const ResourceCard = ({
  blockTitle = 'OFD Resources Cards',
  campus_resource,
  distance = null,
  title,
  id,
  photo = '',
  photo_description = '',
  video_thumb_url = '',
  video_description = ''
}) => {
  const { isVeteran } = useCurrentAppType()
  const { flagImage } = useSelector(prop('school'))
  const { sendEvent } = useAnalytics()

  return (
    <Grow in>
      <Card aria-label={title}>
        <CardActionArea
          component={Link}
          onClick={() =>
            sendEvent('block_interactions', {
              block_title: not(isNil(distance))
                ? 'OFD Geolocal Resources Cards'
                : blockTitle,
              click_element: 'Link',
              element_title: title,
              path: window.location.pathname
            })
          }
          to={`/public/article/${id}/${sanitizeUrl(title)}`}
        >
          <Box sx={{ position: 'relative' }}>
            <CardMedia
              alt={title}
              component="img"
              height="164"
              image={
                isNil(video_thumb_url)
                  ? defaultTo(
                      prop('featured_url', photo),
                      prop('thumb_url', photo)
                    )
                  : video_thumb_url
              }
            />
            {campus_resource && (
              <Box
                sx={{
                  borderBottom: 'solid 50px',
                  borderBottomColor: 'school.flag',
                  borderLeft: 'solid 50px transparent',
                  borderRight: 'solid 50px',
                  borderRightColor: 'school.flag',
                  borderTop: 'solid 50px transparent',
                  bottom: 0,
                  position: 'absolute',
                  right: 0
                }}
              >
                {not(isVeteran) && (
                  <Box
                    alt="wellpower logo"
                    component="img"
                    height={40}
                    src={flagImage}
                    sx={{
                      position: 'absolute',
                      top: 5
                    }}
                    width={40}
                  />
                )}
              </Box>
            )}
          </Box>
          <CardContent>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gridGap: 8,
                textAlign: 'left'
              }}
            >
              {not(isNil(distance)) && (
                <Typography variant="body2">
                  <FormattedMessage
                    defaultMessage="{distance} miles"
                    id="K8jcOk"
                    values={{
                      distance: metersToMiles(distance)
                    }}
                  />
                </Typography>
              )}
              <Typography variant="cardTitle">
                <FormattedMessage
                  defaultMessage="{title}"
                  id="2YgLVn"
                  values={{ title }}
                />
              </Typography>
              <FormattedMessage
                defaultMessage="{message}"
                id="PR2F1h"
                values={{
                  message: or(
                    isEmpty(video_description),
                    isNil(video_description)
                  )
                    ? parse(excerpt(photo_description, 63))
                    : parse(excerpt(video_description, 63))
                }}
              />
            </Box>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grow>
  )
}

ResourceCard.propTypes = {
  blockTitle: PropTypes.string,
  campus_resource: PropTypes.bool.isRequired,
  distance: PropTypes.string,
  id: PropTypes.number.isRequired,
  photo: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  photo_description: PropTypes.string,
  title: PropTypes.string.isRequired,
  video_description: PropTypes.string,
  video_thumb_url: PropTypes.string
}

export default ResourceCard
