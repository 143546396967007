/* eslint-disable sort-keys */
/* eslint-disable jsx-a11y/aria-role */
import { useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import CardsMask from './CardsMask'
import styles from './WelcomeSectionCards.module.scss'
import { Box, Button, Typography } from '@mui/material'
import { useAnalytics, useCurrentAppType } from 'hooks'
import { not, or, prop } from 'ramda'
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

const WelcomeSectionCards = () => {
  const { sendEvent } = useAnalytics()

  const { isResponder, isVeteran } = useCurrentAppType()

  const ref = useRef(null)
  const { name } = useSelector(prop('school'))

  const paragraph_veteran = (
    <>
      <Typography mr={0.5} variant="bodyLarge">
        Here, Veterans get the tools they need to to get things done.
      </Typography>
      <Typography variant="bodyLarge">
        With a free, confidential account Veterans can connect on their own
        terms with: Veteran benefits, VSO contacts, outdoor recreation,
        employment, financial supports, education, family and spouse supports,
        vet groups, VFWs, community support, and so much more.
      </Typography>
    </>
  )
  const paragraph_responder = (
    <>
      <Typography mb={2} variant="bodyLarge">
        Being your best starts with getting the right kind of support, and that
        looks different for everyone and every situation.
      </Typography>
      <Typography variant="bodyLarge">
        No matter what you’re facing, there’s a way forward. Get connected to
        thousands of evidence-based resources across a variety of wellness
        topics including: relationships, work-life balance, finance, resilience,
        depression, sleep, leadership and so much more.
      </Typography>
    </>
  )

  let paragraph = paragraph_responder
  if (isVeteran) paragraph = paragraph_veteran

  const ctaLink_responder = 'get connected to personalized resources'
  const ctaLink_veteran = 'Access Veteran tools with a free account'
  const ctaLink_other = 'sign up to choose your priorities'

  let ctaLink = ctaLink_other
  if (isVeteran) ctaLink = ctaLink_veteran
  if (isResponder) ctaLink = ctaLink_responder

  // const getPicklistHeadlineScreenRead = role => {
  //   if (isVeteran)
  //     return (
  //       <FormattedMessage
  //         defaultMessage="Get well-being tips & tools for {role}. "
  //         id="5Ujp91"
  //         values={{ role }}
  //       />
  //     )
  //     return (
  //       <FormattedMessage
  //         defaultMessage="Find tactical well-being tools for {role}. "
  //         id="sm5pmt"
  //         values={{ role }}
  //       />
  //     )
  // }

  const sendKeenIOEvent = (element_title, click_element) => {
    sendEvent('welcome_page', {
      click_element: click_element,
      element_title: element_title,
      section: 'cards'
    })
  }

  const getHeadline = () => {
    const responderHeadline = 'Be first on scene for yourself.'
    const veteranHeadline = 'Given the right tools, a Veteran can do anything.'

    return (
      <Typography color="primary" variant="h2">
        <FormattedMessage
          defaultMessage="{headline}"
          id="AEeXEL"
          values={{
            headline: isResponder ? responderHeadline : veteranHeadline
          }}
        />
      </Typography>
    )
  }

  const messageContent = () => {
    if (isVeteran)
      return (
        <FormattedMessage
          defaultMessage="Features resources for veterans in your county"
          id="LiyjLD"
        />
      )
    if (isResponder)
      return (
        <FormattedMessage
          defaultMessage="Features resources for responders in your state"
          id="wZ1rBX"
        />
      )
  }

  const getCallout = (org, side) => (
    <Box
      bottom={isVeteran ? '5%' : '8%'}
      className={`callout ${(side, styles)}`}
      color="secondary.dark"
      position="absolute"
      right={isVeteran && [0, null, null, '5%']}
      width="250px"
      zIndex="0"
      {...(side === 'left'
        ? {
            display: ['block', null, null, 'none'],
            margin: '10% 0 0 25%',
            position: 'relative'
          }
        : {
            display: ['none', null, null, 'block']
          })}
    >
      <svg
        className={styles['callout-graphic']}
        height="72"
        viewBox="0 0 113 72"
        width="113"
      >
        <path
          d="M4.9972 34.4531C4.91172 33.3518 5.73517 32.3898 6.83643 32.3043L24.7824 30.9114C25.8837 30.8259 26.8457 31.6493 26.9312 32.7506C27.0167 33.8519 26.1932 34.8139 25.092 34.8994L9.13997 36.1375L10.3781 52.0896C10.4636 53.1908 9.64017 54.1529 8.53891 54.2383C7.43766 54.3238 6.47562 53.5004 6.39014 52.3991L4.9972 34.4531ZM79.8501 32.934C80.6036 32.1263 81.8692 32.0824 82.6768 32.8359C83.4845 33.5894 83.5284 34.8549 82.7749 35.6626L79.8501 32.934ZM8.29173 32.7789C21.2069 43.8336 34.107 49.1745 46.1674 49.1648C58.1886 49.1551 69.685 43.8298 79.8501 32.934L82.7749 35.6626C71.9977 47.2145 59.4941 53.154 46.1706 53.1648C32.8862 53.1755 19.0968 47.2926 5.69067 35.8177L8.29173 32.7789Z"
          fill="#B0B0B0"
        />
        <path
          className={styles['location-icon']}
          d="M92.375 27.6875C92.6562 28.125 93.3125 28.125 93.5938 27.6875C98.1562 21.125 99 20.4375 99 18C99 14.6875 96.3125 12 93 12C89.6562 12 87 14.6875 87 18C87 20.4375 87.8125 21.125 92.375 27.6875ZM93 20.5C91.5938 20.5 90.5 19.4062 90.5 18C90.5 16.625 91.5938 15.5 93 15.5C94.375 15.5 95.5 16.625 95.5 18C95.5 19.4062 94.375 20.5 93 20.5Z"
          fill="#B0B0B0"
        />
        <circle
          cx="93"
          cy="20"
          fill="transparent"
          r="18"
          stroke="#B0B0B0"
          strokeWidth="4"
        />
      </svg>
      <Typography color="secondary.dark" variant="body1">
        {messageContent(org)}
      </Typography>
    </Box>
  )

  const ImageContentColumn = () => {
    const responderImage =
      'images/welcome/phone-images/responder-phone-cards.png'
    const veteranImage = 'images/welcome/phone-images/veteran-phone-cards.png'
    const hash = '/images/welcome/hash-square.svg'

    return (
      <Box
        className="image-content-column"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gridColumn: { xs: 'initial', lg: isVeteran ? 2 : 1 },
          gridRow: { xs: 1, lg: 'initial' },
          justifySelf: isVeteran && 'end',
          pt: '20%',
          width: { xs: 1, lg: '45vw' }
        }}
      >
        <Box
          alignItems={isVeteran ? 'flex-end' : 'initial'}
          className="image-wrapper"
          display="flex"
          flexDirection="column"
          justifyContent={isVeteran ? 'flex-end' : 'flex-start'}
        >
          <Box
            className="hash-square"
            sx={{
              position: 'absolute',
              top: isVeteran
                ? { xs: '5%', md: '8%' }
                : { xs: '-2%', md: '12%' },
              width: { xs: 1, md: '85%', lg: '50%' }
            }}
          >
            <Box
              sx={{
                background: `url(${hash})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                pt: '100%',
                width: 1
              }}
            />
          </Box>

          {(isVeteran || isResponder) && (
            <CardsMask position={isVeteran ? 'right' : 'left'}>
              <Box
                component="img"
                height="100%"
                src={isResponder ? responderImage : veteranImage}
                width="100%"
              />
            </CardsMask>
          )}
          {getCallout(name, 'left')}
        </Box>
      </Box>
    )
  }

  const CopyContentColumn = () => (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gridColumn: isVeteran
          ? { xs: 'initial', lg: 1 }
          : { xs: 'initial', lg: 2 },
        gridRow: { xs: 2, lg: 1 },
        justifyContent: 'center',
        p: { xs: '8%', md: '2% 5%', lg: '0 2% 0 2%', xl: '20% 7%' }
      }}
    >
      {getHeadline()}
      <Typography my={1} variant="bodyLarge">
        <FormattedMessage
          defaultMessage="{paragraph}"
          id="sXRPrT"
          values={{ paragraph }}
        />
      </Typography>
      <Button
        endIcon={<ArrowForwardRoundedIcon />}
        LinkComponent={Link}
        onClick={() => sendKeenIOEvent('Create a new Account', 'Link')}
        sx={{
          color: 'primary.main',
          fontWeight: 700,
          px: 0
        }}
        to="/sign-up"
        variant="text"
      >
        <FormattedMessage
          defaultMessage="{ctaLink}"
          id="QBkvSl"
          values={{ ctaLink }}
        />
      </Button>
      {not(isVeteran) && getCallout(name, 'right')}
    </Box>
  )

  return (
    <Box
      className="welcome-section-cards"
      ref={ref}
      sx={{
        display: 'grid',
        gridTemplateColumns: { xs: '1fr', lg: 'auto auto' },
        pb: or(isVeteran, isResponder)
          ? { xs: 0, lg: '12%', xl: isResponder && 0 }
          : 0,
        position: 'relative'
      }}
    >
      <ImageContentColumn />
      <CopyContentColumn />
    </Box>
  )
}

WelcomeSectionCards.propTypes = {}

export default WelcomeSectionCards
