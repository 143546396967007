import { useEffect, useState } from 'react'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded'
import {
  not,
  isNil,
  prop,
  find,
  propEq,
  or,
  gte,
  length,
  isEmpty,
  path,
  map
} from 'ramda'
import {
  Box,
  Button,
  Container,
  Divider,
  Grow,
  Skeleton,
  Stack,
  Typography
} from '@mui/material'
import { useAnalytics } from 'hooks'
import PropTypes from 'prop-types'
import { convertCardTypeToString } from 'utils/convertCardTypeToString'
import { useSelector } from 'react-redux'

import ArticleContentSource from './ArticleContentSource'
import SocialBadge from 'components/common/SocialBadge'
import ShareButton from './ShareButton'
import Bookmark from './Bookmark'
import RecommendedCards from 'components/common/RecommendedCards'
import RecommendedSubdomains from './RecommendedSubdomains'
import PreparedArticle from './PreparedArticle'
import UserFeedback from './UserFeedback'
import ECRM from './ECRM'
import { useNavigate } from 'react-router-dom'

const Article = ({ card = {} }) => {
  const navigate = useNavigate()
  const { app_type } = useSelector(prop('school'))
  const { userDomains } = useSelector(prop('user'))
  const { sendEvent } = useAnalytics()

  const [eventFired, setEventFired] = useState(false)

  const bg = () => `/images/article-bg-${app_type}.jpg`

  if (or(isNil(userDomains), isEmpty(card)))
    return (
      <Container
        data-testid="skeleton"
        maxWidth="sm"
        sx={{ height: 'calc(100vh - 220px)' }}
      >
        <Stack alignItems="center">
          <Skeleton height={16} variant="text" width={100} />
          <Skeleton height={64} variant="text" width="100%" />
          <Skeleton height={40} variant="text" width={88} />
          <Skeleton height={500} variant="rectangular" width="100%" />
        </Stack>
      </Container>
    )

  const params = {
    campus_resource: prop('campus_resource', card),
    card_domain: prop('domain_name', card),
    card_groups: prop('groups', card)
      ? map(prop('name'), prop('groups', card))
      : [],
    card_id: prop('id', card),
    card_name: prop('title', card).replace('"', ''),
    card_type: convertCardTypeToString(prop('type', card))
  }

  useEffect(() => {
    if (not(isNil(card))) {
      // makes sure we only fire the card_detail_view event once per render.
      // since favoriting a card causes a mutation and a reload, we were getting
      // false card detail view event triggers any time we favorited the card
      if (not(eventFired)) {
        sendEvent('card_detail_view', {
          ...params
        })
        setEventFired(true)
      }
    }
  }, [card, eventFired])

  return (
    <>
      <Container
        sx={{
          background: `url(${bg()})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain'
        }}
      >
        <Stack direction="row" justifyContent="space-between">
          <Button
            color="inherit"
            onClick={() => navigate(-1)}
            startIcon={<ArrowBackRoundedIcon />}
            variant="text"
          >
            Back
          </Button>
        </Stack>
        <Stack
          spacing={3}
          sx={{
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Grow in>
            <Stack alignItems="center" spacing={1}>
              <Stack alignItems="center" spacing={1}>
                <Typography variant="overline">
                  {prop(
                    'title',
                    find(propEq('id', prop('section_id', card)), userDomains)
                  )}
                </Typography>
                <Typography textAlign="center" variant="h1">
                  {prop('title', card)}
                </Typography>
              </Stack>

              {prop('content_source', card) && (
                <ArticleContentSource
                  articleImage={path(['content_source', 'article_image'], card)}
                  articleText={path(['content_source', 'article_text'], card)}
                  link={path(['content_source', 'link'], card)}
                />
              )}

              <Stack alignItems="center" direction="row" spacing={0.5}>
                <Bookmark
                  id={prop('id', card)}
                  isBookmarked={prop('is_favorite', card)}
                  params={params}
                />
                <Box
                  sx={{
                    bgcolor: 'primary.dark',
                    height: '24px',
                    width: '2px'
                  }}
                />
                <ShareButton params={params} title={prop('title', card)} />
              </Stack>

              {gte(prop('card_viewed_counter', card), 50) && (
                <SocialBadge socialCount={prop('card_viewed_counter', card)} />
              )}
            </Stack>
          </Grow>

          <ECRM />

          <PreparedArticle article={card} />

          <Box>
            <UserFeedback {...card} />
          </Box>

          {gte(length(prop('recommended_cards', card)), 1) && (
            <>
              <Divider />
              <RecommendedCards
                blockTitle="Article Recommended"
                recommendedCards={prop('recommended_cards', card)}
                type={prop('type', card)}
              />
            </>
          )}
        </Stack>
      </Container>
      <RecommendedSubdomains subdomains={prop('all_card_domains', card)} />
    </>
  )
}

Article.propTypes = {
  card: PropTypes.shape({})
}

export default Article
