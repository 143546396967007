import { useEffect, useState } from 'react'
import { Button, CircularProgress, Stack } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded'
import {
  dec,
  inc,
  equals,
  length,
  cond,
  always,
  lt,
  not,
  isEmpty,
  isNil,
  assoc,
  prop,
  path,
  propEq,
  defaultTo,
  or,
  and,
  findIndex,
  find
} from 'ramda'
import getParams from 'utils/getParams'
import { useGetAddressCoordinatesQuery } from 'api/addressApi'
import { useUpdateHomeLocationMutation } from 'api/profileApi'
import { useAnalytics, useCurrentAppType } from 'hooks'
import useGetSteps from './useGetSteps'
import {
  useUpdateOnboardedUserMutation,
  useUpdateUserMutation
} from 'api/userApi'
import { useCreateGoalMutation } from 'api/goalsApi'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

const StepNavigation = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { isWellpower } = useCurrentAppType()
  const { sendEvent } = useAnalytics()
  const { step: queryStep } = useParams()
  const { is_secondary_user } = useSelector(prop('user'))
  const {
    selectedInterests,
    completedSelfCheck,
    selectedGoals,
    emailOptIn,
    realityCheckAnswers,
    realityCheckQuestion,
    location
  } = useSelector(prop('onboarding'))

  const steps = useGetSteps()
  const currStep = inc(findIndex(propEq(queryStep, 'url'), steps))

  const { enable_preset_goals } = useSelector(prop('school'))

  const [isLoading, setIsLoading] = useState(false)

  const isNextDisabled = cond([
    [equals('interests'), always(lt(length(selectedInterests), 1))],
    [equals('self-check'), always(not(completedSelfCheck))],
    [equals('goals'), always(lt(length(selectedGoals), 1))],
    [equals('location'), always(or(isEmpty(location), isNil(location)))]
  ])

  // Getting Geo Location
  const formatted = assoc('street', prop('street_line', location), location)
  const { data: coordinates } = useGetAddressCoordinatesQuery(
    getParams({
      key: process.env.REACT_APP_PUBLIC_SMARTY_STREETS_WEB_KEY,
      ...formatted
    }),
    { skip: isEmpty(location) }
  )

  const [updateHomeLocation, { isSuccess }] = useUpdateHomeLocationMutation()
  const [updateUserData, { data: updatedUser }] = useUpdateUserMutation()
  const [updateUser, { data: updatedUserData }] =
    useUpdateOnboardedUserMutation()
  const [createGoal] = useCreateGoalMutation()

  const handleStepChange = (skip = false) => {
    // interests and the user is either a secondary type
    // or the enable goals is false from the school object
    if (
      and(
        equals(queryStep, 'interests'),
        or(is_secondary_user, not(enable_preset_goals))
      )
    ) {
      handleKeenIO(skip)
      navigate('/onboarding/self-check', undefined, { shallow: true })
      return
    }
    // if on goals and isWellpower app type
    // go to location instead of email
    if (and(equals(queryStep, 'goals'), isWellpower)) {
      handleKeenIO(skip)
      navigate('/onboarding/location', undefined, { shallow: true })
      return
    }
    // On goals page set selected goals
    if (equals(queryStep, 'goals')) {
      // need to create new goals that user may have selected in goals step
      // don't care about the status of these calls though so we can just
      // fire em off async.
      selectedGoals.map(goal => {
        const goalBody = new FormData()
        for (const [key, value] of Object.entries(goal)) {
          if (equals(key, 'steps')) {
            prop('steps', goal).forEach((step, index) => {
              goalBody.append('steps[' + index + '][name]', step)
            })
          }
          if (equals(key, 'id')) {
            // when passing to goal creation endpoint, we need to convert id on goal
            // to campus_goal_id since the goal is a separate record in the db and
            // will generate its own id
            goalBody.append('campus_goal_id', value)
          } else {
            goalBody.append(key, value)
          }
        }
        goalBody.delete('steps')
        createGoal(goalBody)
      })
    }
    // if we have a geoLocated school (currently only Wellpower),
    // the last question will be the location question.
    // so we will complete onboarding after we have saved the users home_location
    // otherwise, email opt in is the last question and we just need to complete onboarding
    if (and(equals(queryStep, 'location'), isWellpower)) {
      const formattedAddress = `${prop('street_line', location)}, ${prop(
        'city',
        location
      )} ${prop('state', location)} ${prop('zipcode', location)}`

      const body = {
        address: formattedAddress,
        lat: path(['metadata', 'latitude'], prop(0, coordinates)),
        lng: path(['metadata', 'longitude'], prop(0, coordinates))
      }
      updateHomeLocation(body)
      return
    }
    // If last step (all but wellpower) then complete onboarding
    if (and(equals(queryStep, 'email'), equals(currStep, length(steps)))) {
      handleKeenIO(skip)
      const body = new FormData()
      body.append('email_opt_in', emailOptIn ? 1 : 0)
      updateUserData(body)
      return
    }

    // Steps logic not used above executes down here
    // updating the next step and pushing
    // the new url
    const foundStep = prop(currStep, steps)
    handleKeenIO(skip)
    navigate(`/onboarding/${prop('url', foundStep)}`, undefined, {
      shallow: true
    })
  }

  const handleBack = () => {
    const found = prop(dec(dec(currStep)), steps)
    navigate(`/onboarding/${prop('url', found)}`, undefined, { shallow: true })
  }

  useEffect(() => {
    // After updating home location on success complete onboarding
    if (isSuccess) {
      setIsLoading(true)
      updateUser(new FormData())
    }
  }, [isSuccess])

  // Runs at last step
  useEffect(() => {
    if (or(prop('success', updatedUserData), prop('success', updatedUser))) {
      navigate('/home?justOnboarded=true')
    }
  }, [updatedUserData, updatedUser])

  const handleKeenIO = (skip = false) => {
    const answer = defaultTo(null, realityCheckAnswers[0])

    const keenObj = cond([
      [
        equals('interests'),
        always({
          answers: selectedInterests.map(({ title }) => title),
          question_name: 'INTERESTS_STEP'
        })
      ],
      [
        equals('self-check'),
        always({
          answers: {
            answer: realityCheckQuestion.answers[dec(answer?.score)],
            question: realityCheckQuestion.question
          },
          question_name: 'SELF_CHECK_STEP'
        })
      ],
      [
        equals('goals'),
        always({
          // TODO putting back bad coding standards because of analytics
          // eslint-disable-next-line react/prop-types
          answers: isEmpty(selectedGoals) ? '' : selectedGoals[0].title,
          question_name: 'GOALS_STEP'
        })
      ],
      [
        equals('email'),
        always({
          answers: emailOptIn ? 1 : 0,
          question_name: 'EMAIL_OPT_IN_STEP'
        })
      ]
    ])

    const currentStep = find(propEq('url', queryStep), steps)

    sendEvent('onboarding', {
      ...keenObj(queryStep),
      question: prop('id', currentStep),
      skip
    })
  }

  return (
    <Stack
      sx={{
        bgcolor: 'white',
        bottom: 0,
        boxShadow: 3,
        left: 0,
        position: 'fixed',
        px: 2,
        py: 2,
        right: 0,
        zIndex: 1
      }}
    >
      <Stack
        direction="row"
        sx={{
          justifyContent: 'space-between',
          m: '0 auto',
          maxWidth: 1280,
          width: 1
        }}
      >
        <Button
          aria-label={intl.formatMessage({
            defaultMessage: 'Back Button',
            id: 'FzTVd+'
          })}
          onClick={() => handleBack()}
          startIcon={<ChevronLeftRoundedIcon />}
          sx={{
            fontWeight: 700
          }}
          variant="text"
        >
          <FormattedMessage defaultMessage="Back" id="cyR7Kh" />
        </Button>

        <Stack direction="row" spacing={1}>
          {not(equals(queryStep, 'email')) && (
            <Button
              aria-label={intl.formatMessage({
                defaultMessage: 'Skip Button',
                id: 'SB/IdC'
              })}
              onClick={() => handleStepChange(true)}
              sx={{
                fontWeight: 700
              }}
              variant="text"
            >
              <FormattedMessage defaultMessage="Skip" id="/4tOwT" />
            </Button>
          )}

          <Button
            aria-label={intl.formatMessage({
              defaultMessage: 'Next Button',
              id: 'VWBImm'
            })}
            disabled={or(isNextDisabled(queryStep), isLoading)}
            onClick={() => handleStepChange()}
          >
            {equals(currStep, length(steps)) ? (
              <FormattedMessage
                defaultMessage="Complete Onboarding"
                id="erEjpH"
              />
            ) : isLoading ? (
              <CircularProgress />
            ) : (
              <FormattedMessage defaultMessage="Next" id="9+Ddtu" />
            )}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default StepNavigation
