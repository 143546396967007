import { useEffect, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import ResultsCard from './ResultsCard'
import { prop, filter, not, length, toLower, or, isNil, isEmpty } from 'ramda'
import { Box, Button, Typography } from '@mui/material'
import { Link, useLocation, useParams, useSearchParams } from 'react-router-dom'

const QuizResults = ({ currentQuiz, results }) => {
  const { quiz } = useParams()
  const { pathname } = useLocation()
  const [search] = useSearchParams()
  const selected = search.get('selected')

  const title = prop('title', currentQuiz)
  // we filter out any results with displayAlert as they are too sensitive to offer a judgement on
  // in the results page. Ex: suicidal thoughts.
  const quizResults = useMemo(() => {
    if (or(isNil(results), isEmpty(currentQuiz))) return []
    // return []
    return filter(
      result => not(prop('displayAlert', result)),
      prop('results', currentQuiz)
    )
  }, [currentQuiz, results])

  // any time we click on one of the jump links we set category_results in the url shallowly
  // this use effect scrolls us to the correct element.
  useEffect(() => {
    if (selected) {
      const element = document.getElementById(selected)
      element && element.scrollIntoView({ behavior: 'smooth' })
    }
  }, [selected])

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        gridGap: 24,
        m: '0 auto',
        maxWidth: 1024,
        // eslint-disable-next-line sort-keys
        p: { xs: 2, xl: 0 },
        width: 1
      }}
    >
      <Typography variant="h1">
        <FormattedMessage
          defaultMessage="My {quiz} Results"
          id="2ITuzg"
          values={{ quiz: title }}
        />
      </Typography>
      <Box
        alt=""
        component="img"
        height={132}
        src={`/images/reality-self-checks/${toLower(quiz)}.svg`}
        width={132}
      />
      <Typography
        component="span"
        fontFamily="mark-ot"
        fontSize={20}
        textAlign="center"
        variant="subtitle2"
        width="80%"
      >
        <FormattedMessage
          defaultMessage="Here's the quick recon on your well-being across {topicAreas} topic areas."
          id="a3JDvG"
          values={{
            topicAreas: length(quizResults)
          }}
        />
      </Typography>
      <Typography
        component="span"
        fontSize="12px"
        textTransform="none"
        variant="overline"
      >
        <FormattedMessage defaultMessage="jump to my results in" id="WipLET" />
      </Typography>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexWrap: 'wrap',
          gridGap: 4,
          justifyContent: 'center',
          width: '75%'
        }}
      >
        {quizResults.map(({ id, title }) => (
          <Button
            key={id}
            LinkComponent={Link}
            to={`${pathname}?selected=${id}`}
            variant="option"
          >
            {title}
          </Button>
        ))}
      </Box>
      <Box sx={{ display: 'grid', gridGap: 24, width: 1 }}>
        {quizResults.map(subdomain => (
          <ResultsCard
            key={subdomain.id}
            results={results}
            subdomain={subdomain}
          />
        ))}
      </Box>
    </Box>
  )
}

QuizResults.propTypes = {
  currentQuiz: PropTypes.shape({}).isRequired,
  results: PropTypes.arrayOf(PropTypes.shape({})).isRequired
}

export default QuizResults
