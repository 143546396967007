import { FormattedMessage } from 'react-intl'
import { Button } from '@mui/material'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import { Link } from 'react-router-dom'
import { useAnalytics } from 'hooks'

const SearchBlock = () => {
  const { sendEvent } = useAnalytics()

  const getText = () => (
    <FormattedMessage
      defaultMessage='Try searching for "stress" or "finances"'
      id="DqgudF"
    />
  )

  return (
    <Button
      LinkComponent={Link}
      onClick={() =>
        sendEvent('block_interactions', {
          block_title: 'Explore Search Block',
          click_element: 'Button',
          element_title: 'search',
          path: window.location.pathname
        })
      }
      startIcon={<SearchRoundedIcon />}
      sx={{
        alignSelf: 'center',
        backgroundColor: 'white',
        border: '1px solid',
        borderColor: 'secondary.main',
        color: 'secondary.dark',
        fontFamily: 'mark-ot',
        // eslint-disable-next-line sort-keys
        fontSize: { xs: 14, md: 20 },
        margin: '16px auto',
        textTransform: 'capitalize'
      }}
      to="/search"
      variant=""
    >
      {getText()}
    </Button>
  )
}

export default SearchBlock
