import { FormattedMessage, useIntl } from 'react-intl'
import { getTakeActionLinkInfo, getTakeActionSubtitle } from './const'
import { or, isNil, reduce, propEq, prop, not } from 'ramda'
import { useGetPickupCardsQuery } from 'api/cardsApi'
import { Box, Button, Skeleton } from '@mui/material'
import { Link } from 'react-router-dom'
import { useCurrentAppType } from 'hooks'
import { useSelector } from 'react-redux'

import PickUpWhereYouLeftOffItem from './PickUpWhereYouLeftOffItem'

const PickUpWhereYouLeftOff = () => {
  const { hide_resources, app_type } = useSelector(prop('school'))
  const { userDomains } = useSelector(prop('user'))
  const intl = useIntl()
  const { isVeteran } = useCurrentAppType()

  const { data: cardData } = useGetPickupCardsQuery()

  if (or(isNil(userDomains), isNil(cardData)))
    return (
      <Box
        sx={{
          alignItems: 'flex-start',
          display: 'grid',
          // eslint-disable-next-line sort-keys
          gridGap: { xs: 24, md: 16 },
          // eslint-disable-next-line sort-keys
          gridTemplateColumns: { xs: '1fr', md: 'repeat(3, 1fr)' },
          justifyItems: 'center'
        }}
      >
        <Skeleton height={335} variant="rectangular" width="100%" />
        <Skeleton height={335} variant="rectangular" width="100%" />
        <Skeleton height={335} variant="rectangular" width="100%" />
      </Box>
    )

  const subdomains = reduce(
    (subdomains, domain) => subdomains.concat(domain.subdomains),
    [],
    userDomains
  )
  const getAdviceSubdomain = find(
    propEq('id', prop('domain_id', prop('get_advice', cardData))),
    subdomains
  )
  const takeActionSubdomain = find(
    propEq('id', prop('domain_id', prop('take_action', cardData))),
    subdomains
  )

  const getGetAdviceSubtitle = () => {
    if (isVeteran)
      return (
        <FormattedMessage
          defaultMessage="Expert perspectives, Veteran stories and life hacks in"
          id="Tj9rMY"
        />
      )
    return (
      <FormattedMessage
        defaultMessage="Expert perspectives, responder posts and life hacks in"
        id="PHOjV7"
      />
    )
  }

  const items = [
    {
      card: prop('get_advice', cardData),
      href: prop('link', getAdviceSubdomain),
      linkText: prop('title', getAdviceSubdomain),
      show: true,
      subtitle: not(isNil(app_type)) && getGetAdviceSubtitle()
    },
    {
      card: prop('take_action', cardData),
      href: prop(
        'to',
        getTakeActionLinkInfo(
          takeActionSubdomain,
          prop('take_action', cardData),
          intl
        )
      ),
      linkText: prop(
        'linkText',
        getTakeActionLinkInfo(
          takeActionSubdomain,
          prop('take_action', cardData),
          intl
        )
      ),
      show: true,
      subtitle:
        not(isNil(prop('take_action', cardData))) &&
        getTakeActionSubtitle(prop('take_action', cardData), intl)
    },
    {
      card: prop('campus_resource', cardData),
      href: '/resources',
      linkText: intl.formatMessage({
        defaultMessage: 'Resources',
        id: 'c/KktL'
      }),
      show: not(hide_resources),
      subtitle: intl.formatMessage({
        defaultMessage: 'Explore',
        id: '7JlauX'
      })
    }
  ]

  return (
    <>
      <Box
        sx={{
          alignItems: 'flex-start',
          display: 'grid',
          // eslint-disable-next-line sort-keys
          gridGap: { xs: 24, md: 16 },
          // eslint-disable-next-line sort-keys
          gridTemplateColumns: { xs: '1fr', md: 'repeat(3, 1fr)' },
          justifyItems: 'center'
        }}
      >
        {items.map(
          ({ linkText, show, subtitle, ...rest }) =>
            show && (
              <PickUpWhereYouLeftOffItem
                key={subtitle}
                linkText={linkText}
                subtitle={subtitle}
                {...rest}
              />
            )
        )}
      </Box>
      <Button
        LinkComponent={Link}
        sx={{ alignSelf: 'center' }}
        to="/explore"
        variant="outlined"
      >
        <FormattedMessage defaultMessage="Explore More" id="yxJpxX" />
      </Button>
    </>
  )
}

export default PickUpWhereYouLeftOff
