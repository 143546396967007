import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import AccessibleModal from 'components/common/AccessibleModal'
import image from 'assets/images/onboarding/streamline-icon.png'
import { Box, Button, Stack, Typography } from '@mui/material'
import { useCurrentAppType } from 'hooks'

const ConfirmationModal = ({ open, onClose }) => {
  const { isVeteran } = useCurrentAppType()

  return (
    <AccessibleModal
      onClose={onClose}
      open={open}
      title={
        <Stack>
          {isVeteran ? (
            <Typography sx={{ fontSize: 34 }}>🎖</Typography>
          ) : (
            <Typography sx={{ fontSize: 34 }}>🥳</Typography>
          )}
          <Typography
            sx={{
              mb: 2,
              textTransform: 'none'
            }}
            textAlign="center"
            variant="h3"
          >
            {isVeteran ? (
              <FormattedMessage
                defaultMessage="You completed your first mission."
                id="5/g/Fz"
              />
            ) : (
              <FormattedMessage
                defaultMessage="Way to put you first!"
                id="TIrzXw"
              />
            )}
          </Typography>
        </Stack>
      }
    >
      <Stack alignItems="center">
        <Box
          component="img"
          height={229}
          maxWidth="100%"
          mx="auto"
          src={image}
          width={229}
        />
        <Typography mb={3} textAlign="center" variant="body1">
          {isVeteran ? (
            <FormattedMessage
              defaultMessage="Keep your momentum and dive on in. 🤿"
              id="iDqbvn"
            />
          ) : (
            <FormattedMessage
              defaultMessage="Keep your momentum and dive on in. 🐬"
              id="S7ZTkh"
            />
          )}
        </Typography>

        <Button onClick={onClose}>
          <FormattedMessage defaultMessage="Start exploring" id="faZtRk" />
        </Button>
      </Stack>
    </AccessibleModal>
  )
}

ConfirmationModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
}

export default ConfirmationModal
